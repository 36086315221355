import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import {reducer as toastrReducer} from 'react-redux-toastr'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';
// local reducers
import authReducer from './store/reducers/auth';
import userReducer from './store/reducers/user';
import reducerPro from './store/reducers/reducerPro';
import reducerList from './store/reducers/reducerList';
import reducerUpdate from './store/reducers/reducerUpdate';
import reducerCoreUpdate from './store/reducers/reducerCoreUpdate';
import reducerSyncData from './store/reducers/reducerSyncData';
import reducerPresProdProjects from './store/reducers/setPresProdProjects';
import reducerPreprodProject from './store/reducers/reducerPreprodProject'
import reducerDetailPlugin from './store/reducers/reducerDetailPlugin';
import reducerDetailPluginPreprod from './store/reducers/reducerDetailPluginPreprod';
import reducerUserProdProjects from './store/reducers/reducerUserProdProjects';
import reducerDetailProject from './store/reducers/reducerDetailProject';
import reducerIfPluginPresent from './store/reducers/reducerIfPluginPresent';
import reducerDeletePresProdProjects from './store/reducers/reducerDeletePresProdProjects';
import reducerDeleteUser from './store/reducers/reducerDeleteUser';
import reducerResetPwd from './store/reducers/reducerResetPwd';
import reducerNewPwd from './store/reducers/reducerNewPwd';
import reducerMyProjects from './store/reducers/reducerMyProjects';
import reducerForgetPwd from './store/reducers/reducerForgetPwd';

const SENTRY_FRONT_URL = process.env.REACT_APP_SENTRY_URL;
Sentry.init({dsn: SENTRY_FRONT_URL});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer       = combineReducers({
  auth                  : authReducer,
  user                  : userReducer,
  toastr                : toastrReducer,
  project               : reducerPro,
  list                  : reducerList,
  update                : reducerUpdate,
  coreUpdate            : reducerCoreUpdate,
  syncData              : reducerSyncData,
  presProdProject       : reducerPresProdProjects,
  getPreprodProject     : reducerPreprodProject,
  detailPlugin          : reducerDetailPlugin,
  detailPluginPreprod   : reducerDetailPluginPreprod,
  userProdProject       : reducerUserProdProjects,
  detailProject         : reducerDetailProject,
  ifPluginPresent       : reducerIfPluginPresent,
  DeletepresProdProject : reducerDeletePresProdProjects,
  deleteUser            : reducerDeleteUser,
  email                 : reducerResetPwd,
  password              : reducerNewPwd,
  myProject             : reducerMyProjects,
  forgetEmail           : reducerForgetPwd
});

const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
));

const app = (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
