import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    userProdProjects: [],
  loading: false
}
const fetchUserProdProjectStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
}

const fetchUserProdProjectSuccess = (state, action) => {
  return updateObject(state, {
    userProdProjects: action.userProdProjects,
    loading: false
  });
};

const fecthUserProdProjectFail = (state, action) => {
  return updateObject(state, {
    loading: false
  });
}

const reducerUserProdProjects = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROD_PROJECTS_START: return fetchUserProdProjectStart(state, action);
    case actionTypes.FETCH_USER_PROD_PROJECTS_SUCCESS: return fetchUserProdProjectSuccess(state, action);
    case actionTypes.FETCH_USER_PROD_PROJECTS_FAIL: return fecthUserProdProjectFail(state, action);
    default: return state;
  }
};

export default reducerUserProdProjects;
