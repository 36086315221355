import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for user deleting
export const deleteUserSuccess = (deleteUser,total) => {
  return {
    type: actionTypes.DELETE_PRES_PROD_PROJECTS_SUCCESS,
    deleteUser: deleteUser,
    total: total
  };
};

export const deleteUserFail = (error) => {
  return {
    type: actionTypes.DELETE_USER_FAIL,
    error: error
  };
};

export const deleteUserStart = () => {
  return {
    type: actionTypes.DELETE_USER_START
  };
};
// axios
export const deleteUsers = (token, prodID, userId) => {
  return dispatch => {
    dispatch(deleteUserStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.post(`${BACK_BASE_URL}/api/deleteUser/${prodID}/${userId}`, headerConfig)
      .then(res => {
        console.log(res)
        const deletedUsers = [];
        for (const key in res.data) {
          deletedUsers.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        dispatch(deleteUserSuccess(deletedUsers, total));
        toastr.error('Suppression', 'Lutilisateur et le projet ne sont plus attachés ');
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(deleteUserFail(err));
      })
  }
}
