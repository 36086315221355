import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  projects: [],
  loading: false
}
const fetchProjectStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchProjectSuccess = (state, action) => {
  return updateObject(state, {
    projects: action.projects,
    loading: false
  });
};

const fecthProjectFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerPro = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTS_START: return fetchProjectStart(state, action);
    case actionTypes.FETCH_PROJECTS_SUCCESS: return fetchProjectSuccess(state, action);
    case actionTypes.FETCH_PROJECTS_FAIL: return fecthProjectFail(state, action);
    default: return state;
  }
};

export default reducerPro;
