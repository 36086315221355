import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import '../../styles/main.css';
import { Row, Col, Button } from 'reactstrap';
import Tab2 from '../../components/PluginDetail/Tab2';
import { FaAngleDoubleLeft } from "react-icons/fa";

class Details extends Component {
  intervalID;

  componentDidMount() {
    this.props.onGetUsers(this.props.token);
    this.props.onFetchDetailPluginPreprod(this.props.token, this.props.match.params.id);
    this.props.onFetchDetailPlugin(this.props.token, this.props.match.params.id);
    this.intervalID = setInterval(this.props.onFetchDetailPluginPreprod(this.props.token, this.props.match.params.id), 500);
  }

  componentWillUnmount() {
    /*
      stop getData() from continuing to run even
      after unmounting this component
    */
    clearInterval(this.intervalID);
  }

  render() {
    return (
      <div style={{marginRight:'15cm'}}>
      <br></br>
      <Row display="inline">
        <Col>
          <div>
            <br></br>
        <Button style={{ border: 'white' }} onClick={() => this.props.history.goBack()}>
          <FaAngleDoubleLeft size={18} />
          Retour
        </Button>
        </div>
        <div>
        <Tab2 componentDidMount={() => this.componentDidMount()} users={this.props.users}
          loading3={this.props.loading} loading={this.props.loading}
          detailPlugin={this.props.detailPlugin} detailPluginPreprod={this.props.detailPluginPreprod} />
      </div>
       </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.detailPlugin.loading,
    token: state.auth.token,
    detailPlugin: state.detailPlugin.detailPlugin,
    loading3: state.detailPlugin.loading,
    detailPluginPreprod: state.detailPluginPreprod.detailPluginPreprod,
    users: state.user.users,
    allUsers: state.user.allUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDetailPlugin: (token, id) => dispatch(actions.fetchDetailPlugin(token, id)),
    onFetchDetailPluginPreprod: (token, id) => dispatch(actions.fetchDetailPluginPreprod(token, id)),
    onGetUsers: (token) => dispatch(actions.getUser(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
