import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for Detail Project fetching
export const fetchDetailProjectSuccess = (detailProject,total) => {
  return {
    type: actionTypes.FETCH_DETAILS_PROJECT_SUCCESS,
    detailProject: detailProject,
    total: total
  };
};

export const fetchDetailProjectFail = (error) => {
  return {
    type: actionTypes.FETCH_DETAILS_PROJECT_FAIL,
    error: error
  };
};

export const fetchDetailProjectStart = () => {
  return {
    type: actionTypes.FETCH_DETAILS_PROJECT_START
  };
};
// axios
export const fetchDetailProject = (token, projectId) => {
  return dispatch => {
    dispatch(fetchDetailProjectStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(`${BACK_BASE_URL}/api/detailProject/${projectId}`, headerConfig)
      .then(res => {
        const fecthedDetailProject = [];
        for (const key in res.data) {
          fecthedDetailProject.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        dispatch(fetchDetailProjectSuccess(fecthedDetailProject, total));
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          window.location="/";
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchDetailProjectFail(err));
      })
  }
}
