import React from 'react';
import NavigationItem from './NavigationItem/NavigationItemDrawer';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import { FaWordpressSimple } from "react-icons/fa";
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import { toTitleCase } from '../../shared/utility';
import PeopleOutlineRoundedIcon from '@material-ui/icons/PeopleOutlineRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import FolderOpenRoundedIcon from '@material-ui/icons/FolderOpenRounded';
import List from '@material-ui/core/List';
import { Avatar } from '@material-ui/core';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';

const drawerWidth = 240;

const iconStyling = {
  width: '35px',
  height: '35px',
  color: '#dad4d4'
}

const avatarStyle = {
  height: '40px',
  width: '40px',
  backgroundColor: '#dad4d4',
  color: '#0a0606'
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 0,
    left: -6,
    width: 78,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: 0,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    backgroundColor: '#2F184C',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    backgroundColor: '#2F184C',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 3),
    ...theme.mixins.toolbar,
  },
}));

export default function NavigationItems(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const nameAvatar = localStorage.getItem('fullName') ? toTitleCase(localStorage.getItem('fullName')) : '';

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let navItems = null;
  switch (localStorage.getItem('roles')) {
    case 'ROLE_ADMIN':
      navItems = (
        <React.Fragment>
          <NavigationItem to={"/projects"} primary="Projets" icon={<FaWordpressSimple style={iconStyling} />} />
          <NavigationItem to="/adminpanel" primary="Gestion des projets" icon={<FolderOpenRoundedIcon style={iconStyling} />} />
          <NavigationItem to="/users" primary="Utilisateurs" icon={<PeopleOutlineRoundedIcon style={iconStyling} />} />
        </React.Fragment>
      );
      break;
    default: navItems = (
      <React.Fragment>
        <NavigationItem drawerOpen={open} to="/myprojects" primary="Dashboard" icon={<FaWordpressSimple style={iconStyling} />} />
      </React.Fragment>
    );
  }

  return (
    <div >
      <CssBaseline />
      <AppBar
        style={{ backgroundColor: '#2F184C' }}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <ArrowForwardIosRoundedIcon style={iconStyling} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <h5 style={{ color: '#dad4d4' }} >WP Tracking <br></br> Dashboard</h5>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ArrowForwardIosRoundedIcon style={iconStyling} /> : <ArrowBackIosOutlinedIcon style={iconStyling} />}
          </IconButton>
        </div>
        <Divider style={{ backgroundColor: '#dad4d4' }} />
        <List>
          <br />
          <NavigationItem to="/profile" primary={localStorage.getItem('fullName')} icon={<Avatar style={avatarStyle}>{nameAvatar}</Avatar>} />
          <br />
          <Divider variant="middle" style={{ backgroundColor: '#dad4d4' }} />
          <br />
          <br />
          {navItems}
          <br />
          <Divider variant="middle" style={{ backgroundColor: '#dad4d4' }} />
          <br />
          <br />
          <NavigationItem to="/logout" primary="Déconnexion" icon={<ExitToAppRoundedIcon style={iconStyling} />} />
        </List>
      </Drawer>
    </div>
  )
}
