import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for lists fetching
export const fetchListSuccess = (lists,total) => {
  return {
    type: actionTypes.FETCH_LISTS_SUCCESS,
    lists: lists,
    total: total
  };
};

export const fetchListFail = (error) => {
  return {
    type: actionTypes.FETCH_LISTS_FAIL,
    error: error
  };
};

export const fetchListStart = () => {
  return {
    type: actionTypes.FETCH_LISTS_START
  };
};
// axios
export const fetchLists = (token, projectId) => {
  return dispatch => {
    dispatch(fetchListStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(`${BACK_BASE_URL}/api/project/${projectId}`, headerConfig)
      .then(res => {
        const fecthedLists = [];
        for (const key in res.data) {
            fecthedLists.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        localStorage.setItem('projectId', projectId);
        dispatch(fetchListSuccess(fecthedLists, total));
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          window.location="/";
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchListFail(err));
      })
  }
}
