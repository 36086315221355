import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for upates fetching
export const fetchUpdateSuccess = (updates,total) => {
  return {
    type: actionTypes.FETCH_UPDATES_SUCCESS,
    updates: updates,
    total: total
  };
};

export const fetchUpdateFail = (error) => {
  return {
    type: actionTypes.FETCH_UPDATES_FAIL,
    error: error
  };
};

export const fetchUpdateStart = () => {
  return {
    type: actionTypes.FETCH_UPDATES_START
  };
};
// axios
export const fetchUpdates = (token, pluginId) => {
  return dispatch => {
    dispatch(fetchUpdateStart());

    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.post(`${BACK_BASE_URL}/api/update/${pluginId}`, headerConfig)
      .then(res => {
        console.log( res)
        const fecthedUpdates = [];
        for (const key in res.data) {
          fecthedUpdates.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        dispatch(fetchUpdateSuccess(fecthedUpdates, total));
        toastr.success('Succès', 'Mise à jour du plugin en cours, Veuilliez attendre un peu! ');
        setTimeout(function () {
          toastr.success("Décompression du plugin en cours, veuilliez attendre avant la recharge de la page")
        }, 9000);
        setTimeout(function () {
          document.location.reload()
        }, 40000);
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchUpdateFail(err));
      })
  }
}
