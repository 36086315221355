import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateObject } from '../../shared/utility';
import * as actions from '../../store/actions/index';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import '../../styles/form.css';

class ForgetPassword extends Component {
  state = {
    forget: {
      email: '',
    }
  }

  inputChangeHandler = (event) => {
    const { name, value } = event.target;
    const updatedElements = updateObject(this.state.forget, {
      [name]: [value]
    });
    this.setState({ forget: updatedElements });
  }
  submitHandler = (event) => {
    event.preventDefault();
    this.props.onForgetPassword(JSON.stringify(this.state.forget));
  }

  render() {
    return (
      <div >
        <br></br> <br></br>
        <center><h1>Bienvenue sur Wordpress Tracking Dashboard</h1></center>
        <hr class="my-5"></hr>
        <form onSubmit={this.submitHandler} class="form">
          <center>
            <fieldset class="col-md-5">
              <legend>Entrez votre email </legend>
              <br></br> <br></br>
              <div>
                <div className="form-group">
                  <label > Email  </label>
                  <input name="email" type="text" className="form-control"
                    onChange={(event) => this.inputChangeHandler(event)} />
                </div>
                <br></br>
              </div>
            </fieldset>
          </center>
          <br></br>
          <center>
            <button id="sv_sign_btn"
            style={{ 'background-color': '#37035E', 'width': '40%', 'border-radius': '8px', 'border': 'none' }}
            type="submit" className="btn btn-primary btn-lg"   > Envoyer  </button>
          </center>
        </form>
      </div >
    )
  }
}

const mapStateTpProps = state => {
  return {
    emails: state.forgetEmail.emails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onForgetPassword: (forgetPasswordBody) => dispatch(actions.forgetPwd(forgetPasswordBody))
  };
};

export default connect(mapStateTpProps, mapDispatchToProps)(ForgetPassword);
