import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for core upates fetching
export const fetchCoreUpdateSuccess = (coreUpdates,total) => {
  return {
    type: actionTypes.FETCH_CORE_UPDATES_SUCCESS,
    coreUpdates: coreUpdates,
    total: total
  };
};

export const fetchCoreUpdateFail = (error) => {
  return {
    type: actionTypes.FETCH_CORE_UPDATES_FAIL,
    error: error
  };
};

export const fetchCoreUpdateStart = () => {
  return {
    type: actionTypes.FETCH_CORE_UPDATES_START
  };
};
// axios
export const fetchCoreUpdates = (token, projectId) => {
  return dispatch => {
    dispatch(fetchCoreUpdateStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(`${BACK_BASE_URL}/api/updateCore/${projectId}`, headerConfig)
      .then(res => {
        const fecthedCoreUpdates = [];
        for (const key in res.data) {
          fecthedCoreUpdates.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        dispatch(fetchCoreUpdateSuccess(fecthedCoreUpdates, total));
        toastr.success('Succès', 'Mise à jour du coeur wordpress en cours, Veuilliez attendre un peu! ');
        setTimeout(function () {
          toastr.success("Décompression en cours, veuilliez attendre avant la recharge de la page")
        }, 9000);
        setTimeout(function () {
          document.location.reload()
        }, 80000);
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchCoreUpdateFail(err));
      })
  }
}
