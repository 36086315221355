import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  myProjects: [],
  myProject: null,
  loading: false,
  totalcount: 0,
}
const myProjectsStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const myProjectsSuccess = (state, action) => {
  return updateObject(state, {
    myProjects: action.myProjects,
    myProject: action.myProject,
    loading: false,
    totalcount: action.total
  });
}

const myProjectsFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerMyProjects = (state = initialState, action) => {
  switch (action.type) {
    // add actions
    case actionTypes.MYPROJECTS_START: return myProjectsStart(state, action);
    case actionTypes.MYPROJECTS_SUCCESS: return myProjectsSuccess(state, action);
    case actionTypes.MYPROJECTS_FAIL: return myProjectsFail(state, action);
    default: return state;
  }
}

export default reducerMyProjects;
