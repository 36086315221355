import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Row } from 'reactstrap';
import { Container, TableContainer } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';
import dateFormat from 'dateformat';
import LoadButton from '../../containers/ProjectLists/index'
import Scale from '../../containers/Loader/Scale';
import { updateObject } from '../../shared/utility';
import { MdDeleteForever } from 'react-icons/md';
import ReactTooltip from 'react-tooltip';

toast.configure()
class Tab2 extends Component {
  state = {
    idUser: '',
    loading: true,
    note: {
      email: localStorage.getItem("email"),
      id: '',
      contenu: '',
      idPlugin: ''
    }
  };

  handleSelectedUser = (idUserValue) => {
    this.setState({ idUser: idUserValue });
  }

  handleClick = () => {
    const idP = this.props.detailPlugin[0].id;
    const idU = this.state.idUser[0];
    this.setUserToProdProject(idU, idP);
    this.props.componentDidMount();
  }

  setUserToProdProject = (idU, idP) => {
    this.props.onFetchUserToProdProject(this.props.token, idU, idP);
  }

  inputChangeHandler = (event, id) => {
    // capture event names and their correposnding values
    const { name, value } = event.target;
    const updatedElements = updateObject(this.state.note, {
      idPlugin: id,
      [name]: value
    });
    this.setState({ note: updatedElements });
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.props.onSetNote(this.props.token, JSON.stringify(this.state.note));
    this.props.componentDidMount();

  }

  deleteNote = (id) => {
    this.props.onDeleteNote(this.props.token, id);
    this.props.componentDidMount();
  }


  render() {
    let projectPreprod = <center><Scale loading={this.state.loading} /></center>
    let myprojectPreprod = <center> <Scale loading={this.state.loading} /></center>
    let projectProd = []
    let projectProdTable = null;
    let nameProd = null;
    let namePreprod = null;
    let pluginName = null;
    let projectPreprodTable = null;
    let myprojectProd = []
    let myprojectProdTable = null;
    let myprojectPreprodTable = null;
    if (!this.props.loading && !this.props.loading3) {
      nameProd = this.props.detailPlugin.slice(Math.max(this.props.detailPlugin.length - 1, 0)).map((detail, index) => {
        return <div>
          <h3 > Prod: {detail.name}</h3>
        </div>
      });

      namePreprod = this.props.detailPluginPreprod.slice(Math.max(this.props.detailPluginPreprod.length - 1, 0)).map((detail, index) => {
        return <div>
          <h3 > Preprod: {detail.name}</h3>
        </div>
      });

      pluginName = this.props.detailPlugin.slice(Math.max(this.props.detailPlugin.length - 1, 0)).map((detail, index) => {
        return <div>
          <h3 > Plugin: {detail.pluginName}</h3>
        </div>
      });

      projectPreprodTable = this.props.detailPluginPreprod.slice(Math.max(this.props.detailPluginPreprod.length - 1, 0)).map((detail, index) => {
        return <TableRow key={index}>
          <TableCell width="10%">{detail.versionPreprod} </TableCell>
          <TableCell width="10%">{detail.newVersionPreprod}</TableCell>
          <TableCell width="20%">{dateFormat(detail.time.date, "yyyy-mm-dd hh:mm")} </TableCell>
          <TableCell width="30%">
            {detail.notePreProd !== '' ?
              <label>
                {detail.notePreProd} à {detail.createdAtPreProd} <MdDeleteForever data-tip="Supprimer note" style={{color: '#d80808'}}
                 size={25} onClick={() => this.deleteNote(detail.idNotePreProd)} />
                <ReactTooltip />
              </label>
              :
              <form onSubmit={this.submitHandler} className="form">
                <input name="contenu" placeholder="Laisser une note"
                  onChange={(event) => this.inputChangeHandler(event, detail.idPluginPreprod)} />
              </form>
            }
          </TableCell>
          <TableCell width="10%">
               <LoadButton newVersion={detail.newVersionPreprod} loading={this.state.loading} id={detail.idPluginPreprod} />
          </TableCell>
        </TableRow>
      });

      projectPreprod = (
        <div>
          {this.props.detailPluginPreprod.length === 0 ?
            <div>
              <br></br><br></br>
              <h4>Plugin non existant en Preprod ou site Preprod non affecté
              </h4>
            </div>
            :
            <div>
              {namePreprod}
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Version actuelle</TableCell>
                      <TableCell>Nouvelle version</TableCell>
                      <TableCell>Date </TableCell>
                      <TableCell>Note</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectPreprodTable}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </div>
      )

      projectProdTable = this.props.detailPlugin.slice(Math.max(this.props.detailPlugin.length - 1, 0)).map((detail, index) => {
        return <TableRow key={detail.id}>
          <TableCell width="10%">{detail.version} </TableCell>
          <TableCell width="10%">{detail.newVersion}</TableCell>
          <TableCell width="20%">{dateFormat(detail.time.date, "yyyy-mm-dd hh:mm")} </TableCell>
          <TableCell width="30%">  {detail.note !== '' ?
            <label>
              {detail.note} à {detail.createdAt} <MdDeleteForever  data-tip="Supprimer note"  style={{color: '#d80808'}} size={25}
              onClick={() => this.deleteNote(detail.idNote)} />
              <ReactTooltip />
            </label>
            :
            <form onSubmit={this.submitHandler} className="form">
              <input name="contenu" placeholder="Laisser une note"
                onChange={(event) => this.inputChangeHandler(event, detail.pluginId)} />
            </form>
          }</TableCell>
          <TableCell width="10%">
            <LoadButton newVersion={detail.newVersion} loading={this.state.loading} id={detail.pluginId} /></TableCell>
        </TableRow>
      });

      projectProd = (
        <div >
          {nameProd}
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Version actuelle</TableCell>
                  <TableCell>Nouvelle version</TableCell>
                  <TableCell>Date </TableCell>
                  <TableCell>Note</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectProdTable}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
      myprojectPreprodTable = this.props.detailPluginPreprod.slice(Math.max(this.props.detailPluginPreprod.length - 1, 0)).map((detail, index) => {
        return <TableRow key={index}>
          <TableCell>{detail.versionPreprod} </TableCell>
          <TableCell>{detail.newVersionPreprod}</TableCell>
          <TableCell>{dateFormat(detail.time.date, "yyyy-mm-dd hh:mm")} </TableCell>
          <TableCell>
            {detail.notePreProd === '' ?
              <form onSubmit={this.submitHandler} className="form">
                <input name="contenu" placeholder="Laisser une note"
                  onChange={(event) => this.inputChangeHandler(event, detail.idPluginPreprod)} />
              </form>
              :
              <label>{
                detail.notePreProd} à {detail.createdAtPreProd}  <MdDeleteForever data-tip="Supprimer note"  style={{color: '#d80808'}}
                size={20} onClick={() => this.deleteNote(detail.idNotePreProd)} />
              </label>
            }
          </TableCell>
          <TableCell>
               <LoadButton newVersion={detail.newVersionPreprod} loading={this.state.loading} id={detail.idPluginPreprod} />
          </TableCell>
        </TableRow>
      });
      myprojectPreprod = (
        <div>
          {this.props.detailPluginPreprod.length === 0 ?
            <div>
              <br></br><br></br>
              <h4>Plugin non existant en Preprod ou site Preprod non affecté
              </h4>
            </div>
            :
            <div>
              {namePreprod}
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Version actuelle</TableCell>
                      <TableCell>Nouvelle version</TableCell>
                      <TableCell>Date </TableCell>
                      <TableCell>Note</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myprojectPreprodTable}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </div>
      )

      myprojectProdTable = this.props.detailPlugin.slice(Math.max(this.props.detailPlugin.length - 1, 0)).map((detail, index) => {
        return <TableRow key={detail.id}>
          <TableCell>{detail.version} </TableCell>
          <TableCell>{detail.newVersion}</TableCell>
          <TableCell>{dateFormat(detail.time.date, "yyyy-mm-dd hh:mm")} </TableCell>
          <TableCell>
            {detail.note === '' ?
              <form onSubmit={this.submitHandler} className="form">
                <input name="contenu" placeholder="Laisser une note"
                  onChange={(event) => this.inputChangeHandler(event, detail.pluginId)} />
              </form>
              :
              <label>
                {detail.note} à {detail.createdAt}  <MdDeleteForever data-tip="Supprimer note"  style={{color: '#d80808'}}
                size={20} onClick={() => this.deleteNote(detail.idNote)} />
              </label>
            }
          </TableCell>
          <TableCell>
            <LoadButton newVersion={detail.newVersion} loading={this.state.loading} id={detail.pluginId} />
          </TableCell>
        </TableRow>
      });
      myprojectProd = (
        <div >
          {nameProd}
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Version actuelle</TableCell>
                  <TableCell>Nouvelle version</TableCell>
                  <TableCell>Date </TableCell>
                  <TableCell>Action</TableCell>
                  <TableCell>Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myprojectProdTable}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
    }

    return (<Container>
      <br></br>
      <center> <h1>{pluginName}</h1></center>
      <br></br>
      {localStorage.getItem("roles") === 'ROLE_ADMIN' ?
        <Row>
          {projectPreprod}
          <br></br>
        </Row>
        :
        <Row>
          {myprojectPreprod}
          <br></br>
        </Row>
      }
      {localStorage.getItem("roles") === 'ROLE_ADMIN' ?
        <Row>
          {projectProd}
        </Row>
        :
        <Row>
          {myprojectProd}
        </Row>
      }
    </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    loadingUpdate: state.update.loading,
    token: state.auth.token,
    updates: state.update.updates,
    userProdProject: state.userProdProject.userProdProject,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUpdates: (token, id) => dispatch(actions.fetchUpdates(token, id)),
    onSetNote: (token, note) => dispatch(actions.setNote(token, note)),
    onDeleteNote: (token, noteId) => dispatch(actions.deleteNote(token, noteId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tab2);
