import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardDeck, Container, Button } from 'reactstrap';
import * as actions from '../../store/actions/index';
import '../../styles/main.css'
import 'font-awesome/css/font-awesome.min.css';
import Project from '../../components/Project/Project';
import Scale from '../Loader/Scale';

class MyProjects extends Component {
  state = {
    loading: true,
  }

  componentDidMount() {
    var email = {
      email: localStorage.getItem("email")
    }
    this.props.onFetchMyProjects(this.props.token, JSON.stringify(email));
  }
  viewMovieInfo = (id) => {
    this.props.onFetchLists(this.props.token, id);
  }

  synchronizeData = () => {
    this.props.onFetchSyncData(this.props.token);
  }

  render(props) {
    let project = (
      <CardDeck>
        {
          this.props.myProjects.map((project, i) => {
            return (
              <Project key={i} viewMovieInfo={this.viewMovieInfo} project={project} />
            )
          })
        }
      </CardDeck>
    )
    if (this.props.loading) {
      project = <Scale loading={this.state.loading}/>
    }

    return (
      <Container>
        <br></br>
        <Button onClick={() => this.synchronizeData()}><i className="fa fa-recycle">Synchronisation</i></Button>
        <center><h1>Liste des projets</h1></center>
        {project}
      </Container>
    )
  }

}
const mapStateToProps = state => {
  return {
    myProjects: state.myProject.myProjects,
    loading: state.project.loading,
    token: state.auth.token,
    totaC: state.project.totalcount,
    lists: state.list.lists,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchMyProjects: (token, myProjectsData) => dispatch(actions.myProjects(token, myProjectsData)),
    onFetchLists: (token, id) => dispatch(actions.fetchLists(token, id)),
    onFetchSyncData: (token) => dispatch(actions.fetchSyncData(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProjects)
