import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  users: [],
  user: null,
  oneUser:null,
  notes: [],
  note: null,
  loading: false,
  totalcount: 0
}

const fetchUserStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchUserSuccess = (state, action) => {
  return updateObject(state, {
    users: action.users,
    loading: false,
    totalcount: action.total
  });
};

const fecthUserFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const getUserStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const getUserSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loading: false
  });
};

const getUserFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const setUserStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const setUserSuccess = (state, action) => {
  return updateObject(state, {
    user: action.user,
    loading: false
  });
};

const setUserFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const setNoteStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const setNoteSuccess = (state, action) => {
  return updateObject(state, {
    note: action.note,
    loading: false
  });
};

const setNoteFail = (state, action) => {
  return updateObject(state, { loading: false })
}

// delete note actions
const deleteNoteStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const deleteNoteSuccess = (state, action) => {
  return updateObject(state, {
      note: action.note,
      loading: false,
  });
}

const deleteNoteFail = (state, action) => {
  return updateObject(state, { loading: false });
}

// update user actions
const updateUserStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const updateUserSuccess = (state, action) => {
  return updateObject(state, {
      user: action.user,
      loading: false,
  });
}

const updateUserFail = (state, action) => {
  return updateObject(state, { loading: false });
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // fetch user data
    case actionTypes.FETCH_USERS_START: return fetchUserStart(state, action);
    case actionTypes.FETCH_USERS_SUCCESS: return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL: return fecthUserFail(state, action);
     // get  all users
     case actionTypes.GET_USER_START: return getUserStart(state, action);
     case actionTypes.GET_USER_SUCCESS: return getUserSuccess(state, action);
     case actionTypes.GET_USER_FAIL: return getUserFail(action, state);
    // update user state
    case actionTypes.SET_USER_START: return setUserStart(state, action);
    case actionTypes.SET_USER_SUCCESS: return setUserSuccess(state, action);
    case actionTypes.SET_USER_FAIL: return setUserFail(action, state);
    // set Note
    case actionTypes.SET_NOTE_START: return setNoteStart(state, action);
    case actionTypes.SET_NOTE_SUCCESS: return setNoteSuccess(state, action);
    case actionTypes.SET_NOTE_FAIL: return setNoteFail(action, state);
    // delete Note
    case actionTypes.DELETE_NOTE_START: return deleteNoteStart(state, action);
    case actionTypes.DELETE_NOTE_SUCCESS: return deleteNoteSuccess(state, action);
    case actionTypes.DELETE_NOTE_FAIL: return deleteNoteFail(action, state);
    // update User
    case actionTypes.UPDATE_USER_START: return updateUserStart(state, action);
    case actionTypes.UPDATE_USER_SUCCESS: return updateUserSuccess(state, action);
    case actionTypes.UPDATE_USER_FAIL: return updateUserFail(action, state);
    default: return state;
  }
};

export default reducer;
