import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import '@material/react-select/dist/select.css';
import { Label, Button } from 'reactstrap';
import { Container } from '@material-ui/core';
import MaterialTable from 'material-table';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import BackspaceIcon from '@material-ui/icons/Backspace';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import Spinner from '../../components/UI/Spinner/Spinner';
import { FaAngleDoubleLeft } from "react-icons/fa";
import { toastr } from 'react-redux-toastr';
import OneSelect from './OneSelect';

class AdminPanel extends Component {
  intervalID;
  state = {
    idProject: ''
  }

  columns = [
    { title: 'Projet Prod', field: 'name', editable: 'never' },
    {
      title: 'Projet Preprod', field: 'project', render: rowData =>
        rowData.preprodName === '' ?
          <OneSelect projectPreprod={this.props.preprodProjects} onSelectProject={this.handleSelectedProject} />
          :
          <Label>{rowData.preprodName}</Label>
    },
  ]

  handleSelectedProject = (idProjectValue) => {
    this.setState({ idProject: idProjectValue });
  }

  removeProdFromPreprod = (idP, idPP) => {
    this.props.onDeletePresProdProjects(this.props.token, idP, idPP);
    this.componentDidMount();
  }

  componentDidMount() {
    this.props.onFetchProjects(this.props.token);
    this.props.onFetchPreprodProjects(this.props.token);
    this.intervalID = setInterval(this.props.onFetchProjects(this.props.token), 500);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  setPresProdProject = (idP, idPP) => {
    if (this.state.idProject === '') {
      toastr.error('Veuilliez choisir un projet!');
    } else {
      this.props.onFetchPresProdProjects(this.props.token, idPP, idP);
      this.componentDidMount();
    }
  }

  render() {
    const projectsTableData = [];
    for (const key in this.props.projects) {
      projectsTableData.push({
        ...this.props.projects[key],
      });
    }
    const goBack = (
      <div >
        <br></br>
        <Button style={{ border: 'white' }} onClick={() => this.props.history.goBack()}>
          <FaAngleDoubleLeft size={18} />
        Retour
      </Button>
      </div>
    )

    const projects = (<MaterialTable
      title="Gestion des projets"
      columns={this.columns}
      data={projectsTableData}
      LoadingComponent={Spinner}
      editable={{
        isDeletable: rowData => rowData.preprodName !== '',
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = [...projectsTableData];
                const index = data.indexOf(oldData);
                console.log(index);
                this.removeProdFromPreprod(oldData.id, oldData.preprodId)
              }
              resolve()
            }, 1500)
          }),
      }}
      options={{
        actionsColumnIndex: -1,
        headerStyle: {
          position: 'sticky',
          top: 0,
          fontSize: '25px'
        },
      }}
      localization={{
        body: {
          editRow: {
            deleteText: 'Etes vous sûre de vouloir faire la désaffectation?' }
          }
        }}
      icons={{
        Edit: BorderColorRoundedIcon,
        Search: YoutubeSearchedForIcon,
        ResetSearch: BackspaceIcon
      }}
      actions={[
        rowData => ({
          icon: 'save',
          tooltip: 'Affecter les projets',
          onClick: (event, rowData) => this.setPresProdProject(this.state.idProject[0], rowData.id),
          disabled: rowData.preprodName !== ''
        }),
      ]}
      isLoading={this.props.loading}
      onSearchChange={this.onSearchHandler}
    />);
    return (
      <Container>
        <br></br>
        {goBack}
        <br>
        </br>
        {projects}
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    projects: state.project.projects,
    preprodProjects: state.getPreprodProject.preprodProjects,
    loading: state.project.loading,
    token: state.auth.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchPreprodProjects: (token) => dispatch(actions.fetchPreprodProjects(token)),
    onFetchSyncData: (token) => dispatch(actions.fetchSyncData(token)),
    onFetchProjects: (token) => dispatch(actions.fetchProjects(token)),
    onFetchPresProdProjects: (token, idP, idPP) => dispatch(actions.fetchPresProdProjects(token, idP, idPP)),
    onDeletePresProdProjects: (token, idP, idPP) => dispatch(actions.deletePresProdProjects(token, idP, idPP))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
