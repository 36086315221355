import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  emails: [],
  email: null,
  loading: false,
  totalcount: 0,
}
const forgetPwdStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const forgetPwdSuccess = (state, action) => {
  return updateObject(state, {
    emails: action.emails,
    email: action.email,
    loading: false,
    totalcount: action.total
  });
}

const forgetPwdFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerForgetPwd = (state = initialState, action) => {
  switch (action.type) {
    // add actions
    case actionTypes.FORGETPWD_START: return forgetPwdStart(state, action);
    case actionTypes.FORGETPWD_SUCCESS: return forgetPwdSuccess(state, action);
    case actionTypes.FORGETPWD_FAIL: return forgetPwdFail(state, action);
    default: return state;
  }
}

export default reducerForgetPwd;

