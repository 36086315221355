import React, { Component } from "react";
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from "@emotion/core";
import RingLoader from "react-spinners/RingLoader";
import { FaSync } from "react-icons/fa";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

class ButtonLoader extends Component {
  state = {
    isDisconnected: false,
    loading: false,
    satatus: ''
  }

  handleConnection = (satatus) => {
    this.setState({ isDisconnected: satatus }, function () {
    });
  }

  notify = (id) => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      this.props.onFetchCoreUpdates(this.props.token, id);
      this.setState({
        loading: true
      })
    }
    else {
      toast.error("Vérifier votre connexion internet!")
    }
  };

  render() {
    if (this.state.loading === false) {
      return (<Button disabled={this.props.newVersion === ''}
        onClick={() => this.notify(this.props.id)}
        color="link" style={{ color: '#000000' }} >
        <FaSync size={20} style={{ color: 'black' }} />
      </Button>
      )
    }
    else {
      return (<div className="sweet-loading">
        <RingLoader
          css={override}
          size={35}
          color={"#3fe0de"}
          loading={this.state.loading}
        />
      </div>)
    }
  }
}

const mapStateToProps = state => {
  return {
    coreUpdates: state.coreUpdate.coreUpdates,
    token: state.auth.token,
    loading: state.coreUpdate.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCoreUpdates: (token, id) => dispatch(actions.fetchCoreUpdates(token, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonLoader);
