import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    detailPluginPreprod: [],
    loading: false
}
const fetchDetailPluginPreprodStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchDetailPluginPreprodSuccess = (state, action) => {
  return updateObject(state, {
    detailPluginPreprod: action.detailPluginPreprod,
    loading: false
  });
};

const fecthDetailPluginPreprodFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerDetailPluginPreprod = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DETAILS_PLUGIN_PREPROD_START: return fetchDetailPluginPreprodStart(state, action);
    case actionTypes.FETCH_DETAILS_PLUGIN_PREPROD_SUCCESS: return fetchDetailPluginPreprodSuccess(state, action);
    case actionTypes.FETCH_DETAILS_PLUGIN_PREPROD_FAIL: return fecthDetailPluginPreprodFail(state, action);
    default: return state;
  }
};

export default reducerDetailPluginPreprod;
