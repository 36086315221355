import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;

export const fetchUserSuccess = (users, total) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    users: users,
    total: total
  };
};

export const fectUserFail = (error) => {
  return {
    type: actionTypes.FETCH_USERS_FAIL,
    error: error
  };
};

export const fetchUserStart = () => {
  return {
    type: actionTypes.FETCH_USERS_START
  };
};
// axios get users link
export const fetchUsers = (token, pageNb, itemsPerPage, searchName, roles, pagination) => {
  return dispatch => {
    dispatch(fetchUserStart());
    const headerConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    axios.get(`${BACK_BASE_URL}/api/users?isActive=true&fullName=${searchName}&page=${pageNb + 1}&itemsPerPage=${itemsPerPage}
    &roles=${roles}&pagination=${pagination}`, headerConfig)
      .then(res => {
        const fetchedUsers = [];
        let i = 1;
        for (const key in res.data) {
          fetchedUsers.push({
            ...res.data[key],
            counter: i
          });
          i++;
        }
        axios.get(`${BACK_BASE_URL}/api/users?isActive=true`, headerConfig)
          .then(res => {
            const totalUsers = [];
            let i = 1;
            for (const key in res.data) {
              totalUsers.push({
                ...res.data[key],
                counter: i
              });
              i++;
            }
            const total = totalUsers.length;
            dispatch(fetchUserSuccess(fetchedUsers, total));
          })
      })
      .catch(err => {
        if (err !== null) {
          if (err.response.status === 401) {
            window.location = "/";
            toastr.warning(err.response.data.message, 'Sign in again');
            dispatch(logout());
          }
        }
        dispatch(fectUserFail(err));
      })
  }

}
///////////////////////////////////////////////    FOR USERS UPDATE

export const setUserSuccess = (user, users) => {
  return {
    type: actionTypes.SET_USER_SUCCESS,
    users: [...users],
    user: user
  };
};
export const setUserFail = (error) => {
  return {
    type: actionTypes.SET_USER_FAIL,
    error: error
  };
};

export const setUserStart = () => {
  return {
    type: actionTypes.SET_USER_START
  };
};
// axios link
export const setUser = (token, email) => {
  return dispatch => {
    dispatch(setUserStart());
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${BACK_BASE_URL}/api/activateUsers`, email, { headers })
      .then(res => {
        console.log(res);
        dispatch(setUserSuccess(res.data, email));
        toastr.success('Succès', 'Utilisateur(s) activé(s) ');
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Please sign in again');
          dispatch(logout());
        }
        dispatch(fectUserFail(err));
      })
  }
}

///////////////////////////////////////////////    get all USERS

export const getUserSuccess = (users, total) => {
  return {
    type: actionTypes.FETCH_USERS_SUCCESS,
    users: users,
    total: total
  };
};
export const getUserFail = (error) => {
  return {
    type: actionTypes.GET_USER_FAIL,
    error: error
  };
};

export const getUserStart = () => {
  return {
    type: actionTypes.GET_USER_START
  };
};
// axios link
export const getUser = (token) => {
  return dispatch => {
    dispatch(getUserStart());
    const headers = {
      Authorization: `Bearer ${token}`
    };
    axios.get(`${BACK_BASE_URL}/api/users?isActive=true`, { headers })
      .then(res => {
        const fetchedUsers = [];
        let i = 1;
        for (const key in res.data) {
          fetchedUsers.push({
            ...res.data[key],
            counter: i
          });
          i++;
        }
        const total = fetchedUsers.length;
        dispatch(getUserSuccess(fetchedUsers, total));
      })
      .catch(err => {
        if (err !== null) {
          if (err.response.status === 401) {
            window.location = "/";
            toastr.warning(err.response.data.message, 'Sign in again');
            dispatch(logout());
          }
        }
        dispatch(getUserFail(err));
      })
  }

}

///////////////////////////////////////////////    FOR note add

export const setNoteSuccess = (note, notes) => {
  return {
    type: actionTypes.SET_NOTE_SUCCESS,
    notes: [...notes],
    note: note
  };
};
export const setNoteFail = (error) => {
  return {
    type: actionTypes.SET_NOTE_FAIL,
    error: error
  };
};

export const setNoteStart = () => {
  return {
    type: actionTypes.SET_NOTE_START
  };
};
// axios link
export const setNote = (token, note) => {
  return dispatch => {
    dispatch(setNoteStart());
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${BACK_BASE_URL}/api/comment`, note, { headers })
      .then(res => {
        dispatch(setNoteSuccess(res.data, note));
        toastr.success('Succès', 'Note ajoutée');
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Please sign in again');
          dispatch(logout());
        }
        dispatch(setNoteFail(err));
      })
  }
}


////////////// for deleting note //////////////
export const deleteNoteSuccess = (note, total) => {
  return {
    type: actionTypes.DELETE_NOTE_SUCCESS,
    note: note,
    total: total
  };
};

export const deleteNoteFail = (error) => {
  return {
    type: actionTypes.DELETE_NOTE_FAIL,
    error: error
  };
}

export const deleteNoteStart = () => {
  return {
    type: actionTypes.DELETE_NOTE_START
  }
}

export const deleteNote = (token, idNote) => {
  return dispatch => {
    dispatch(deleteNoteStart());
    const headersConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };

    axios.delete(`${BACK_BASE_URL}/api/deleteComment/${idNote}`, { headersConfig })
      .then(res => {
        console.log(res)
        const deletedNote = [];
        for (const key in res.data) {
          deletedNote.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        dispatch(deleteNoteSuccess(deletedNote, total));
        toastr.error('Suppression', 'Note supprimée');
      })
      .catch(err => {
        if (err.response.status === 401) {
          toastr.warning('your session is expired', 'Please Sign in again');
          dispatch(logout());
        }
        dispatch(deleteNoteFail(err));
      });
  }
}

////////////// for update user //////////////
export const updateUserSuccess = (user, users) => {
  return {
    type: actionTypes.UPDATE_USER_SUCCESS,
    users: [...users],
    user: user
  };
};

export const updateUserFail = (error) => {
  return {
    type: actionTypes.UPDATE_USER_FAIL,
    error: error
  };
}

export const updateUserStart = () => {
  return {
    type: actionTypes.UPDATE_USER_START
  }
}

export const updateUser = (token, data) => {
  return dispatch => {
    dispatch(deleteNoteStart());
    const headersConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };

    axios.put(`${BACK_BASE_URL}/api/updateUser`, data, { headersConfig })
      .then(res => {
        dispatch(updateUserSuccess(res.data, data));
        toastr.success('Modification', 'Modification faite avec succès');
      })
      .catch(err => {
        if (err.response.status === 403) {
          toastr.error('Mot de passe actuel incorrect!');

        }
        if (err.response.status === 401) {
          toastr.warning('Session expirée', 'Reconnectez vous!');
          dispatch(logout());
        }
        dispatch(deleteNoteFail(err));
      });
  }
}
