import * as actionTypes from './actions-types';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for mail sending
export const forgetPwdSuccess = (email, emails) => {
  return {
    type: actionTypes.FORGETPWD_SUCCESS,
    emails: [...emails],
    email: email
  };
};

export const forgetPwdFail = (error) => {
  return {
    type: actionTypes.FORGETPWD_FAIL,
    error: error
  };
};

export const forgetPwdStart = () => {
  return {
    type: actionTypes.FORGETPWD_START
  };
};

export const forgetPwd = (forgetPwdBody) => {
  return dispatch => {
    dispatch(forgetPwdStart());
    const headers = {
      'Content-Type': 'application/json'
    };
    axios.post(`${BACK_BASE_URL}/api/users/reset_password`, forgetPwdBody, { headers })
      .then(res => {
        dispatch(forgetPwdSuccess(res.data['hydra:member'], forgetPwdBody));
        toastr.success('Un email vient d\'être envoyé à l\'adresse que vous avez saisie ! ');
        window.location="/";
      })
      .catch(err => {
        console.log(JSON.stringify(err.response));
      })
  }
}
