import React from 'react';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Tooltip from '@material-ui/core/Tooltip';

export default function NavigationItemDrawer(props) {
  const { icon, primary, to } = props;
  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <NavLink
      to={to} ref={ref} {...itemProps} />),
    [to],
  );
  return (
    <li>
      <Tooltip placement="right" title={primary}>
        <ListItem button component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText style={{ color: '#dad4d4' }} primary={primary} />
        </ListItem>
      </Tooltip>
    </li>
  )
}
