import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  updates: [],
  loading: false
}
const fetchUpdateStart = (state, action) => {
  return updateObject(state, {
      loading: true
    });
}

const fetchUpdateSuccess = (state, action) => {
  return updateObject(state, {
    updates: action.updates,
    loading: false
  });
};

const fecthUpdateFail = (state, action) => {
  return updateObject(state, {
      loading: false
    });
}

const reducerUpdate = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_UPDATES_START: return fetchUpdateStart(state, action);
    case actionTypes.FETCH_UPDATES_SUCCESS: return fetchUpdateSuccess(state, action);
    case actionTypes.FETCH_UPDATES_FAIL: return fecthUpdateFail(state, action);
    default: return state;
  }
};

export default reducerUpdate;
