import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  syncData: [],
  loading: false
}
const fetchSyncDataStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchSyncDataSuccess = (state, action) => {
  return updateObject(state, {
    syncData: action.SyncData,
    loading: false
  });
};

const fecthSyncDataFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerSyncData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SYNCDATA_START: return fetchSyncDataStart(state, action);
    case actionTypes.FETCH_SYNCDATA_SUCCESS: return fetchSyncDataSuccess(state, action);
    case actionTypes.FETCH_SYNCDATA_FAIL: return fecthSyncDataFail(state, action);
    default: return state;
  }
};

export default reducerSyncData;
