import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    detailProject: [],
    loading: false
}
const fetchDetailProjectStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchDetailProjectSuccess = (state, action) => {
  return updateObject(state, {
    detailProject: action.detailProject,
    loading: false
  });
};

const fecthDetailProjectFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerDetailProject = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DETAILS_PROJECT_START: return fetchDetailProjectStart(state, action);
    case actionTypes.FETCH_DETAILS_PROJECT_SUCCESS: return fetchDetailProjectSuccess(state, action);
    case actionTypes.FETCH_DETAILS_PROJECT_FAIL: return fecthDetailProjectFail(state, action);
    default: return state;
  }
};

export default reducerDetailProject;
