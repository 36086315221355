import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for pres Prod Projects fetching
export const fetchPresProdProjectSuccess = (presProdProjects,total) => {
  return {
    type: actionTypes.FETCH_PRES_PROD_PROJECTS_SUCCESS,
    presProdProjects: presProdProjects,
    total: total
  };
};

export const fetchPresProdProjectFail = (error) => {
  return {
    type: actionTypes.FETCH_PRES_PROD_PROJECTS_FAIL,
    error: error
  };
};

export const fetchPresProdProjectStart = () => {
  return {
    type: actionTypes.FETCH_PRES_PROD_PROJECTS_START
  };
};
// axios
export const fetchPresProdProjects = (token, presProdID,prodId) => {
  return dispatch => {
    dispatch(fetchPresProdProjectStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.post(`${BACK_BASE_URL}/api/setPreProdProject/${presProdID}/${prodId}`, headerConfig)
      .then(res => {
        console.log( res)
        const fecthedPresProdProjects = [];
        for (const key in res.data) {
          fecthedPresProdProjects.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        dispatch(fetchPresProdProjectSuccess(fecthedPresProdProjects, total));
        toastr.success('Succès', 'Affectation faite avec succès ');
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchPresProdProjectFail(err));
      })
  }
}
