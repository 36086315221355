import * as actionTypes from './actions-types';
import axios from 'axios';

const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for mail sending
export const resetPwdSuccess = (email, emails) => {
  return {
    type: actionTypes.RESETPWD_SUCCESS,
    emails: [...emails],
    email: email
  };
};

export const resetPwdFail = (error) => {
  return {
    type: actionTypes.RESETPWD_FAIL,
    error: error
  };
};


export const resetPwdStart = () => {
  return {
    type: actionTypes.RESETPWD_START
  };
};

export const resetPwd = (token, resetPwdBody) => {
  return dispatch => {
    dispatch(resetPwdStart());
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${BACK_BASE_URL}/api/users/reset_password`, resetPwdBody, { headers })
      .then(res => {
        console.log(res.data);
        dispatch(resetPwdSuccess(res.data['hydra:member'], resetPwdBody));
      })
      .catch(err => {
        dispatch(resetPwdFail(err));
      })
  }
}
