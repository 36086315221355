import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { updateObject, checkValidity } from '../../shared/utility';
import * as actions from '../../store/actions/index';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import '../../styles/form.css';
import { css } from "@emotion/core";
import HashLoader from "react-spinners/HashLoader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class Auth extends Component {
  state = {
    loading: true,
    controls: {
      email: {
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false
      },
      password: {
        value: '',
        validation: {
          required: true,
          minLength: 5
        },
        valid: false,
        touched: false
      }
    }
  }

  componentDidMount() {
    if (this.props.authRedirectPath !== '/') {
      this.props.onSetAuthRedirectPath();
    }
  }

  inputChangeHandler = (event) => {
    // capture event names and their correposnding values
    const eventValue = event.target.value;
    const inputName = event.target.name;
    const updatedElements = updateObject(this.state.controls, {
      [inputName]: updateObject(this.state.controls[inputName], {
        value: eventValue,
        valid: checkValidity(eventValue, this.state.controls[inputName].validation),
        touched: true
      })
    });
    // updatedElements to update state values
    this.setState({ controls: updatedElements });
  }
  // handling form submission
  submitHandler = (event) => {
    event.preventDefault();
    this.props.onAuth(
      this.state.controls.email.value,
      this.state.controls.password.value
    );
  }
  // disabled button property
  disableSubmitHandler = () => {
    return !(this.state.controls.email.valid && this.state.controls.password.valid);
  }

  render() {
    //  dispaly a form or a spinner
    let form = (
      <div>
        <br></br>
        <br></br>
        <h1><center>Bienvenue sur Wordpress Tracking Dashboard</center></h1>
        <div className="welcome">
          <center><span>Made with <span role="img" aria-label="heart" >❤️</span> by CosaVostra</span></center>
        </div>
        <hr className="my-5" />
        <center><h3>Connexion</h3></center>
        <div className="form-group">
          <label>Adresse e-mail</label>
          <input name="email" type="email" className="form-control" placeholder="Enter email"
            onChange={(event) => this.inputChangeHandler(event)} />
          <div style={{ fontSize: 12, color: "red" }}>
            {!this.state.controls.email.valid && this.state.controls.email.touched ? "invalid Email" : null}
          </div>
        </div>
        <div className="form-group">
          <label>Mot de passe</label>
          <input name="password" type="password" className="form-control" placeholder="Enter password"
            onChange={this.inputChangeHandler} />
          <div style={{ fontSize: 12, color: "red" }}>
            {!this.state.controls.password.valid && this.state.controls.password.touched ? "invalid Password" : null}
          </div>
        </div>
        <div className="form-group">
          <div className="custom-control custom-checkbox">
            <input type="checkbox" className="custom-control-input" id="customCheck1" />
            <label className="custom-control-label" htmlFor="customCheck1">Se souvenir de moi</label>
          </div>
        </div>
        <div className="form-group">
          <input id="sv_sign_btn" disabled={this.disableSubmitHandler()} className="btn button-submit" type="submit" value="Se connecter" />
        </div>
        <p className="forgot-password text-right">
          <br></br>
          <Link to="/forgetpwd">Mot de passe oublié ?</Link>
        </p>
      </div>
    );

    if (this.props.loading) {
      form =
        <div className="sweet-loading">
          <br></br><br></br><br></br><br></br> <br></br><br></br><br></br><br></br>
          <center>
            <HashLoader
              css={override}
              size={150}
              color={"#000000"}
              loading={this.state.loading}
            />
          </center>
        </div>
    }
    // afer a successful login , redirect to home page
    let authRedirect = null;
    if (this.props.isAuthenticated) {
      //.log('redirection ' + this.props.authRedirectAuth);
      authRedirect = <Redirect to={this.props.authRedirectAuth} />
    }
    return (
      <div className="col-sm-4 container" >
        {authRedirect}
        <form onSubmit={this.submitHandler}>
          {form}
        </form>
      </div>
    )
  }
}

const mapStateTpProps = state => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectAuth: state.auth.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
  };
};

export default connect(mapStateTpProps, mapDispatchToProps)(Auth);
