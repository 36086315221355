import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  coreUpdates: [],
  loading: false
}
const fetchCoreUpdateStart = (state, action) => {
  return updateObject(state, {
      loading: true
    });
}

const fetchCoreUpdateSuccess = (state, action) => {
  return updateObject(state, {
    coreUpdates: action.coreUpdates,
    loading: false
  });
};

const fecthCoreUpdateFail = (state, action) => {
  return updateObject(state, {
      loading: false
    });
}
const reducerCoreUpdate = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CORE_UPDATES_START: return fetchCoreUpdateStart(state, action);
    case actionTypes.FETCH_CORE_UPDATES_SUCCESS: return fetchCoreUpdateSuccess(state, action);
    case actionTypes.FETCH_CORE_UPDATES_FAIL: return fecthCoreUpdateFail(state, action);
    default: return state;
  }
};

export default reducerCoreUpdate;
