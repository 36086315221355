import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { Row, Col, Button } from 'reactstrap';
import { Container, TableContainer } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { toast } from 'react-toastify';
import ButtonLoader from '../../containers/ProjectLists/ButtonLoader';
import Scale from '../Loader/Scale';
import { updateObject } from '../../shared/utility';
import { MdDeleteForever } from 'react-icons/md';
import OneSelect from '../../components/PluginDetail/OneSelect';

toast.configure()
class Tab2 extends Component {
  state = {
    loading: false,
    idUser: '',
    note: {
      email: localStorage.getItem("email"),
      id: '',
      contenu: '',
    }
  }

  inputChangeHandler = (event) => {
    // capture event names and their correposnding values
    const { name, value } = event.target;
    const updatedElements = updateObject(this.state.note, {
      id: this.props.id,
      [name]: value
    });
    this.setState({ note: updatedElements });
  }

  submitHandler = (event) => {
    event.preventDefault();
    this.props.onSetNote(this.props.token, JSON.stringify(this.state.note));
    this.props.componentDidMount();

  }

  inputChangeHandler2 = (event, id) => {
    // capture event names and their correposnding values
    const { name, value } = event.target;
    const updatedElements = updateObject(this.state.note, {
      id: id,
      [name]: value
    });
    this.setState({ note: updatedElements });
  }

  submitHandler2 = (event) => {
    event.preventDefault();
    this.props.onSetNote(this.props.token, JSON.stringify(this.state.note));
    this.props.componentDidMount();

  }

  deleteNote = (id) => {
    this.props.onDeleteNote(this.props.token, id);
    this.props.componentDidMount();
  }

  handleSelectedUser = (idUserValue) => {
    this.setState({ idUser: idUserValue });
  }
  handleClick = () => {
    const idP = this.props.id;
    const idU = this.state.idUser[0];
    this.setUserToProdProject(idU, idP);
    this.props.componentDidMount();
  }

  setUserToProdProject = (idU, idP) => {
    this.props.onFetchUserToProdProject(this.props.token, idU, idP);
  }


  render() {
    let projectPreprod = <Scale loading={this.props.loading} />
    let myprojectPreprod = <Scale loading={this.state.loading} />
    let projectProd = [];
    let projectPreprodTable = null;
    let projectProdTable = null;
    let message = null;
    let myprojectProd = [];
    let myprojectPreprodTable = null;
    let myprojectProdTable = null;
    let mymessage = null;
    if (!this.props.loading) {
      projectPreprodTable = this.props.detailProject.map((detail, index) => {
        return <TableRow key={index}>
          <TableCell width="30%" >{detail.preprodName} </TableCell>
          <TableCell width="5%" >{detail.preprodVersion} </TableCell>
          <TableCell width="5%" >{
              detail.newVersion === "" ?
                <label><center>-</center></label>
                :
                <label><center>{detail.newVersion}</center></label>
            }</TableCell>
          <TableCell width="40%" >
            {detail.notePreProd !== '' ?
              <label>{detail.notePreProd} à {detail.createdAtPreProd}  <MdDeleteForever style={{ color: '#d80808' }} size={20}
              onClick={() => this.deleteNote(detail.idNotePreProd)} /> </label>
              :
              <form onSubmit={this.submitHandler2} className="form">
                <center>
                  <input name="contenu" placeholder="Laisser une note"
                    onChange={(event) => this.inputChangeHandler2(event, detail.preprodId)} />
                </center>
              </form>
            }
          </TableCell>
          <TableCell width="10%" >
            <ButtonLoader newVersion={detail.preprodNewVersion} loading={this.state.loading} id={detail.preprodId} />
          </TableCell>
        </TableRow>
      });
      projectProdTable = this.props.detailProject.map((detail, index) => {
        return <TableRow key={index}>
          <TableCell width="25%" >{detail.name} </TableCell>
          <TableCell width="30%" >
            {
              detail.fullName !== '' ?
                <label>{detail.fullName}</label>
                :
                <OneSelect onSelectUser={this.handleSelectedUser} users={this.props.users} />
            }
          </TableCell>
          <TableCell width="5%">{detail.version} </TableCell>
          <TableCell width="5%">
            {
              detail.newVersion === "" ?
                <label><center>-</center></label>
                :
                <label><center>{detail.newVersion}</center></label>
            }
          </TableCell>
          <TableCell width="40%">
            {detail.note !== '' ?
              <center>
                <label>{detail.note} à {detail.createdAt} <MdDeleteForever style={{ color: '#d80808' }} size={20} onClick={() => this.deleteNote(detail.idNote)} /> </label>
              </center>
              :
              <form onSubmit={this.submitHandler} className="form">
                <center>
                  <input name="contenu" placeholder="Laisser une note"
                    onChange={(event) => this.inputChangeHandler(event)} />
                </center>
              </form>
            }
          </TableCell>
          <TableCell width="10%">
            {
              detail.fullName === '' ?
                <Button disabled={this.state.idUser === ''} onClick={this.handleClick} >Affecter </Button>
                :
                <ButtonLoader newVersion={detail.newVersion} loading={this.state.loading} id={detail.id} />
            }

          </TableCell>
        </TableRow>
      });
      projectPreprod = (
        <div>
          {this.props.preprodName === '' ?
            <div>
              <br></br><br></br>
              <h4>Allez dans 'Gestion des Projets' pour affecter un projet de preprod</h4>
            </div>
            :
            <div>
              <h3><center>Preprod </center></h3>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Projet</TableCell>
                      <TableCell>Version actuelle</TableCell>
                      <TableCell>Nouvelle version</TableCell>
                      <TableCell><center>Note</center></TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projectPreprodTable}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </div>
      );

      message = this.props.detailProject.map((detail, index) => (
        detail.preprodName === '' ?
          <div>
            <h4>Allez dans 'Gestion des Projets' pour affecté un preprod à ce projet!</h4>
          </div>
          :
          <div>{projectPreprod}</div>
      ));

      projectProd = (
        <div>
          <div>
            <h3><center>Prod </center></h3>
            <TableContainer component={Paper}>
              <Table style={{ width: "auto", tableLayout: "auto" }} >
                <TableHead>
                  <TableRow>
                    <TableCell>Projet</TableCell>
                    <TableCell>Développeur</TableCell>
                    <TableCell>Version actuelle</TableCell>
                    <TableCell>Nouvelle version</TableCell>
                    <TableCell><center>Note</center></TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectProdTable}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )

      myprojectPreprodTable = this.props.detailProject.map((detail, index) => {
        return <TableRow key={index}>
          <TableCell>{detail.preprodName} </TableCell>
          <TableCell>{detail.preprodVersion} </TableCell>
          <TableCell>{detail.preprodNewVersion}</TableCell>
          <TableCell>
            {detail.notePreProd === '' ?
              <form onSubmit={this.submitHandler2} className="form">

                <input name="contenu" placeholder="Laisser une note"
                  onChange={(event) => this.inputChangeHandler2(event, detail.preprodId)} />
              </form>
              :
              <label>{detail.notePreProd} à {detail.createdAtPreProd}  <MdDeleteForever style={{ color: '#d80808' }} size={20}
              onClick={() => this.deleteNote(detail.idNotePreProd)} /></label>
            }
          </TableCell>
          <TableCell>
            <ButtonLoader newVersion={detail.preprodNewVersion} loading={this.state.loading} id={detail.preprodId} />
          </TableCell>
        </TableRow>
      });

      myprojectProdTable = this.props.detailProject.map((detail, index) => {
        return <TableRow key={index}>
          <TableCell>{detail.name} </TableCell>
          <TableCell>{detail.version} </TableCell>
          <TableCell>{detail.newVersion}</TableCell>
          <TableCell>
            {detail.note === '' ?
              <form onSubmit={this.submitHandler} className="form">

                <input name="contenu" placeholder="Laisser une note"
                  onChange={(event) => this.inputChangeHandler(event)} />
              </form>
              :
              <label>{detail.note} à {detail.createdAt} <MdDeleteForever style={{ color: '#d80808' }} size={20} onClick={() => this.deleteNote(detail.idNote)} /></label>
            }
          </TableCell>
          <TableCell>
            <ButtonLoader newVersion={detail.newVersion} loading={this.state.loading} id={detail.id} />
          </TableCell>
        </TableRow>
      });

      myprojectPreprod = (
        <div>
          {this.props.preprodName === '' ?
            <div>
              <br></br><br></br>
              <h4>Preprod non affecté</h4>
            </div>
            :
            <div>
              <h3><center>Preprod </center></h3>
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Projet</TableCell>
                      <TableCell>Version actuelle</TableCell>
                      <TableCell>Nouvelle version</TableCell>
                      <TableCell>Note</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {myprojectPreprodTable}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </div>
      );

      mymessage = this.props.detailProject.map((detail, index) => (
        detail.preprodName === '' ?
          <div>
            <h4>Preprod non affecté</h4>
          </div>
          :
          <div>{myprojectPreprod}</div>
      ));

      myprojectProd = (
        <div>
          <div>
            <h3><center>Prod </center></h3>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Projet</TableCell>
                    <TableCell>Version actuelle</TableCell>
                    <TableCell>Nouvelle version</TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {myprojectProdTable}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )
    }

    return (<Container>
      <br></br>
      <Row display="inline">
        {localStorage.getItem("roles") === 'ROLE_ADMIN' ?
          <Col>
            {message}
          </Col>
          :
          <Col>
            {mymessage}
          </Col>
        }
        {localStorage.getItem("roles") === 'ROLE_ADMIN' ?
          <Col>
            {projectProd}
          </Col>
          :
          <Col>
            {myprojectProd}
          </Col>
        }
      </Row>
    </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    coreUpdates: state.coreUpdate.coreUpdates,
    token: state.auth.token,
    note: state.user.note,
    loading: state.coreUpdate.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCoreUpdates: (token, id) => dispatch(actions.fetchCoreUpdates(token, id)),
    onSetNote: (token, note) => dispatch(actions.setNote(token, note)),
    onDeleteNote: (token, noteId) => dispatch(actions.deleteNote(token, noteId)),
    onFetchUserToProdProject: (token, idU, idP) => dispatch(actions.fetchUserToProdProject(token, idU, idP)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tab2);
