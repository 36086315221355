import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    ifPluginPresent: [],
    loading: false
}
const fetchIfPluginPresentStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchIfPluginPresentSuccess = (state, action) => {
  return updateObject(state, {
    ifPluginPresent: action.ifPluginPresent,
    loading: false
  });
};

const fecthIfPluginPresentFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerIfPluginPresent = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_IF_PLUGIN_PRESENT_START: return fetchIfPluginPresentStart(state, action);
    case actionTypes.FETCH_IF_PLUGIN_PRESENT_SUCCESS: return fetchIfPluginPresentSuccess(state, action);
    case actionTypes.FETCH_IF_PLUGIN_PRESENT_FAIL: return fecthIfPluginPresentFail(state, action);
    default: return state;
  }
};

export default reducerIfPluginPresent;
