import React, { Component } from 'react';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import '../../styles/main.css';
import { css } from "@emotion/core";
import ScaleLoader from "react-spinners/ScaleLoader";
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class Scale extends Component {

  render() {
    return (
        <center> <div className="sweet-loading">
        <ScaleLoader
          css={override}
          size={80}
          color={"#550C64"}
          loading={this.props.loading}
        />
      </div>
      </center>
    )
  }
}

export default Scale;
