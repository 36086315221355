import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  preprodProjects: [],
  loading: false
}
const fetchPreprodProjectStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchPreprodProjectSuccess = (state, action) => {
  return updateObject(state, {
    preprodProjects: action.preprodProjects,
    loading: false
  });
};

const fecthPreprodProjectFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerPreprodProject = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PREPROD_PROJECTS_START: return fetchPreprodProjectStart(state, action);
    case actionTypes.FETCH_PREPROD_PROJECTS_SUCCESS: return fetchPreprodProjectSuccess(state, action);
    case actionTypes.FETCH_PREPROD_PROJECTS_FAIL: return fecthPreprodProjectFail(state, action);
    default: return state;
  }
};

export default reducerPreprodProject;
