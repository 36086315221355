import React, { Component } from 'react'
import { connect } from 'react-redux'
import { CardDeck, Button, Container } from 'reactstrap';
import * as actions from '../../store/actions/index';
import Project from '../../components/Project/Project';
import '../../styles/main.css';
import 'font-awesome/css/font-awesome.min.css';
import Scale from '../Loader/Scale';
import {BsSearch} from 'react-icons/bs';
import { Row } from 'react-bootstrap';

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      itemsPerPage: 1,
      searchName: ''
    }
    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    this.props.onFetchProjects(this.props.token, this.state.itemsPerPage, this.state.searchName);
  }

  viewMovieInfo = (id) => {
    this.props.onFetchLists(this.props.token, id);
  }

  synchronizeData = () => {
    this.props.onFetchSyncData(this.props.token);
    this.props.onFetchProjects(this.props.token, this.state.itemsPerPage, this.state.searchName);
  }

  loadMore() {
    this.setState({ itemsPerPage: this.state.itemsPerPage + 1 });
    this.props.onFetchProjects(this.props.token, this.state.itemsPerPage + 1, this.state.searchName);
  }

  onSearchHandler = (event) => {
    if(event.target.value!==''){
    this.props.onFetchProjects(this.props.token, 100,event.target.value);
    }else{
      this.props.onFetchProjects(this.props.token, this.state.itemsPerPage,event.target.value);

    }
  }

  render() {
    let project = (
      <div>
       
        <br></br>
        <CardDeck>
          <div className='row'>
            {
              this.props.projects.map((project, i) => {
                return (
                  <div >
                    <Project key={i} viewMovieInfo={this.viewMovieInfo} project={project} />
                  </div>
                )
              })
            }
          </div>
        </CardDeck>
        <br></br>
        <br></br>
        <br></br>
        <div className="form-group">
          <center>
          <button onClick={this.loadMore} style={{width: '10%'}} className="button-submit btn"> Charger plus </button>
          </center>
        </div>
      </div>
    )

    if (this.props.loading || this.props.sync) {
      project = <Scale loading={this.state.loading} />
    }

    return (
      <div >
        <br></br>
        <Button onClick={() => this.synchronizeData()}><i className="fa fa-recycle">Synchronisation</i></Button>
        <center><h1>Liste des projets</h1></center>
        <br />

        <Container style={{marginLeft:"35cm"}}>
          <Row>
        <input type="text" style={{width: '15%', height: '25px', borderRadius:'10px'}}  onChange={(event) => this.onSearchHandler(event)} 
        placeholder="Recherche..." name="name" className="form-control" />
        <BsSearch size="23px"/>
        </Row>
        </Container>
        <div>
        {project}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    projects: state.project.projects,
    loading: state.project.loading,
    token: state.auth.token,
    totaC: state.project.totalcount,
    lists: state.list.lists,
    sync: state.syncData.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchProjects: (token, item, searchName) => dispatch(actions.fetchProjects(token, item, searchName)),
    onFetchLists: (token, id) => dispatch(actions.fetchLists(token, id)),
    onFetchSyncData: (token, id) => dispatch(actions.fetchSyncData(token))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects)
