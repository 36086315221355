import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    detailPlugin: [],
    loading: false
}
const fetchDetailPluginStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchDetailPluginSuccess = (state, action) => {
  return updateObject(state, {
    detailPlugin: action.detailPlugin,
    loading: false
  });
};

const fecthDetailPluginFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerDetailPlugin = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_DETAILS_PLUGIN_START: return fetchDetailPluginStart(state, action);
    case actionTypes.FETCH_DETAILS_PLUGIN_SUCCESS: return fetchDetailPluginSuccess(state, action);
    case actionTypes.FETCH_DETAILS_PLUGIN_FAIL: return fecthDetailPluginFail(state, action);
    default: return state;
  }
};

export default reducerDetailPlugin;
