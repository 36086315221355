import React, { Component } from "react";
import { connect } from 'react-redux';
import { updateObject, checkValidity } from '../../shared/utility';
import * as actions from '../../store/actions/index';
import { toastr } from 'react-redux-toastr';

class Profile extends Component {

  state = {
    controls: {
      name: {
        value: localStorage.getItem('fullName'),
        valid: false,
        touched: false
      },
      mypass: {
        value: '',
        validation: {
          //required: true,
          minLength: 5
        },
        valid: false,
        touched: false
      },
      pass: {
        value: '',
        validation: {
          //required: true,
          minLength: 5
        },
        valid: false,
        touched: false
      },
      confirmPassword: {
        value: '',
        validation: {
          //required: true,
          minLength: 5
        },
        valid: false,
        touched: false
      }
    }
  }

  inputChangeHandler = (event) => {
    const eventValue = event.target.value;
    const inputName = event.target.name;
    const updatedElements = updateObject(this.state.controls, {
      [inputName]: updateObject(this.state.controls[inputName], {
        value: eventValue,
        valid: checkValidity(eventValue, this.state.controls[inputName].validation),
        touched: true
      })
    });
    // updatedElements to update state values
    this.setState({ controls: updatedElements });
  }

  // disabled button property
  disableSubmitHandler = () => {
    return !(this.state.controls.confirmPassword.value && this.state.controls.pass.valid);
  }


  // handling form submission
  submitHandler = (event) => {
    event.preventDefault();
    const data = {
      email:localStorage.getItem('email'),
      mypass: this.state.controls.mypass.value,
      pass: this.state.controls.pass.value,
      name:this.state.controls.name.value
    }
    if (this.state.controls.pass.value === this.state.controls.confirmPassword.value) {
      this.props.onProfileUpdate(this.props.token,JSON.stringify(data));
    } else {
      toastr.warning('Mots de passe non identiques');
    }

  }

  render() {
    return (
      <div>
        <main class="main p-4">
          <div class="card col-md-10 col-xl-6 m-auto">
            <div class="card-body">
              <h2>Mon compte</h2>
              <form onSubmit={this.submitHandler}  className="mt-3">
                <div className="form-row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label for="profile_name" className="required">Nom et Prénom</label>
                      <input type="text" name="name"  autofocus="autofocus" onChange={(event) => this.inputChangeHandler(event)}
                      className="form-control" value={localStorage.getItem('fullName')} />
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="profile_email">Adresse e-mail</label>
                      <input type="email" name="profile[email]" disabled="disabled" className="form-control" value={localStorage.getItem('email')} />
                    </div>
                  </div>

                </div>
                <hr className="mt-4 mb-4" />
                <div>
                  <h5 className="mb-4">Changer mon mot de passe</h5>

                  <div className="form-group">
                    <label for="current_password">Mot de passe actuel</label>
                    <input type="password" className="form-control" onChange={(event) => this.inputChangeHandler(event)} name="mypass" />
                  </div>
                  <div className="form-row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label for="profile_plainPassword_first">Nouveau mot de passe</label>
                        <input type="password" id="profile_plainPassword_first" onChange={(event) => this.inputChangeHandler(event)} name="pass" className="form-control" />
                        <div style={{ fontSize: 12, color: "red" }}>
                    {!this.state.controls.pass.valid && this.state.controls.pass.touched ? "Mot de passe très court" : null}
                  </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group"><label for="profile_plainPassword_second">Confirmation du mot de passe</label>
                      <input type="password" id="profile_plainPassword_second" onChange={(event) => this.inputChangeHandler(event)}
                      name="confirmPassword" className="form-control" /></div>
                    </div>
                  </div>
                </div>
                <div class="mt-4">
                  <div class="form-group"><button type="submit" id="profile_submit" name="profile[submit]" class="button-submit btn">
                    Mettre à jour</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.passwords,
    totalC: state.user.totalcount
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    onProfileUpdate: (token, data) => dispatch(actions.updateUser(token, data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps) (Profile);
