import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import MaterialTable from 'material-table';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import '../../styles/main.css';
import { Row, Col, Button } from 'reactstrap';
import {  TableContainer } from '@material-ui/core';
import Spinner from '../../components/UI/Spinner/Spinner';
import { NavLink } from 'react-router-dom';
import ButtonLoader from './ButtonLoader';
import ButtonPluginLoader from './index';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FaAngleDoubleLeft } from "react-icons/fa";
import Scale from '../Loader/Scale';
import ReactTooltip from 'react-tooltip';

class ProjectLists extends Component {
  state = {
    isDisconnected: false,
    loading: true,
    columns: [
      { title: 'Nom du plugin', field: 'name' },
      { title: 'Version actuelle', field: 'version' },
      { title: 'Nouvelle version si disponible', field: 'newVersion',
      render: rowData =>
      rowData.newVersion === '' ?
      <label><center>-</center></label>
      :
      <label><center>{rowData.newVersion}</center></label>
      },
    ],
  }

  viewPluginInfo = (id) => {
    this.props.onFetchDetailPlugin(this.props.token, id);
    this.props.onFetchDetailPluginPreprod(this.props.token, id);
  }

  viewProjectInfo = (id) => {
    this.props.onFetchDetailProject(this.props.token, id);
  }
  componentDidMount(props) {
    this.props.onFetchLists(this.props.token, this.props.match.params.id);
  }

  updateCore = (id) => {
    this.props.onFetchCoreUpdates(this.props.token, id);
  }

  render() {
    console.log(this.props);
    let lists = <Scale loading={this.props.loading}/>
    let project = null
    let projectTable = null;
    if (!this.props.loading) {
      projectTable = this.props.lists.slice(Math.max(this.props.lists.length - 1, 0)).map((detail, index) => {
        return <TableRow key={index}>
          <TableCell style={{ backgroundColor: '#EEE' }}>
            <Button color="link" style={{ color: '#000000' }} data-tip="aller sur le site"
            onClick={()=> window.open(detail.url)} >{detail.projectName}
            </Button>
          </TableCell>
          <TableCell style={{ backgroundColor: '#EEE' }}><center>{detail.wpVersion}</center></TableCell>
          {detail.wpNewVersion!==""?
            <TableCell style={{ backgroundColor: '#EEE' }}> <center>{detail.wpNewVersion}</center> </TableCell>
            :
            <TableCell style={{ backgroundColor: '#EEE' }}><center>-</center> </TableCell>
          }
          <TableCell style={{ backgroundColor: '#EEE' }}> <center>
            <ButtonLoader newVersion={detail.wpNewVersion} data-tip="mettre à jour le coeur WP"
            loading={this.state.loading} id={this.props.match.params.id} />
            <NavLink to={`/projetDetail/${this.props.match.params.id}`} viewPluginInfo={this.viewPluginInfo}>
              <span style={{ fontSize: '1.8em' }} role="img" data-tip="detail" aria-label="loop">🔎</span>
            </NavLink>,
            <Button color="link" style={{ color: '#000000' }} onClick={()=> window.open(detail.url+"/wp-admin")} >
              <span style={{ fontSize: '1.8em' }} data-tip="aller sur le dashboard" role="img" aria-label="link">🔗</span>
            </Button>
            <ReactTooltip />
          </center>
          </TableCell>
        </TableRow>
      });
      project = (
        <div>
          <div >
            <br></br>
            <Button style={{ border: 'white' }} onClick={() => this.props.history.goBack()}>
              <FaAngleDoubleLeft size={18} />
              Retour
            </Button>
          </div>
          <h1><center>Projet </center></h1>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Nom du projet</TableCell>
                  <TableCell><center>Version wordpress actuelle</center></TableCell>
                  <TableCell><center>Nouvelle version si disponible</center> </TableCell>
                  <TableCell><center>Action</center> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectTable}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )
      lists = (<MaterialTable
        title="Les plugins installés"
        columns={this.state.columns}
        data={this.props.lists}
        LoadingComponent={Spinner}
        options={{
          actionsColumnIndex: -1,
          rowStyle: {
            backgroundColor: '#EEE',
          }
        }}

        actions={[
          rowData => ({
            icon: () => {
              return (<ButtonPluginLoader  newVersion={rowData.newVersion} loading={this.state.loading} id={rowData.id} />
              )
            },
            tooltip: 'Mettre à jour Plugin',
            isFreeAction: true,
            disabled: rowData.newVersion.length <= 0,
          }),
          rowData => ({
            icon: () =>
              <NavLink to={`/pluginDetail/${rowData.id}`} viewPluginInfo={this.viewPluginInfo}>
                <span role="img" aria-label="loop">🔎</span>
              </NavLink>,
            tooltip: 'Details',
            onClick: (event, rowData) => { this.viewPluginInfo(rowData.id) },
          }),
        ]}
      />)
    }

    return (
      <div>
        <br></br>
        <br></br>
        <Row>
          <Col >
            <br></br>
            <div>
              {project}
            </div>
          </Col>
        </Row>
        <br></br>
        <br></br>
        <br></br>
        <Row>
          <Col >
            {lists}
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    lists: state.list.lists,
    loading: state.list.loading,
    loadingUpdate: state.update.loading,
    token: state.auth.token,
    totaC: state.list.totalcount,
    updates: state.update.updates,
    detailPlugin: state.detailPlugin.detailPlugin,
    detailPluginPreprod: state.detailPluginPreprod.detailPluginPreprod,
    projectId: state.list.lists.slice(Math.max(state.list.lists.length - 1, 0))
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchLists: (token, id) => dispatch(actions.fetchLists(token, id)),
    onFetchUpdates: (token, id) => dispatch(actions.fetchUpdates(token, id)),
    onFetchDetailPlugin: (token, id) => dispatch(actions.fetchDetailPlugin(token, id)),
    onFetchDetailPluginPreprod: (token, id) => dispatch(actions.fetchDetailPluginPreprod(token, id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectLists);
