import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';
import { Button, Label } from 'reactstrap';
import { Container } from '@material-ui/core';
import MaterialTable from 'material-table';
import BorderColorRoundedIcon from '@material-ui/icons/BorderColorRounded';
import BackspaceIcon from '@material-ui/icons/Backspace';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import '@material/react-menu/dist/menu.css';
import '@material/react-select/dist/select.css';
import SelectUser from './SelectUser';
import { FaAngleDoubleLeft } from "react-icons/fa";
import { toastr } from 'react-redux-toastr';
import TablePagination from '@material-ui/core/TablePagination';

class Users extends Component {
  intervalID;

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      itemsPerPage: 5,
      search: '',
      selectedItem: '',
      projects: this.props.projects,
      language: '',
      email: '',
      emails: [],
      users: this.props.users
    }

  }

  columns = [
    { title: 'Nom', field: 'fullName', editable: 'never' },
    { title: 'Email', field: 'email', editable: 'never' },
    { title: 'Poste', field: 'jobTitle', editable: 'never' },
    {
      title: 'Rôle', field: 'role', editable: 'never' ,
      render: rowData => <Label> Développeur</Label>
    },
    {
      title: 'Statut', field: 'activate',editable: 'never' , render: rowData => rowData.activate === false ?
        <label > Inactif </label>
        :
        <label>Actif</label>
    },
    {
      title: 'Projet', field: 'project',editable: 'onUpdate' ,
      render: rowData =>
        rowData.projects.length === 0 ?
          <SelectUser activate={rowData.activate} onSelectLanguage={this.handleLanguage} selected={this.setSelected} projects={this.props.projects} />
          :
          <label>{rowData.projects[0]['name']}</label>
    },
    {
      title: 'Affectaion', field: 'action',
      render: rowData => (
        <Button disabled={rowData.projects.length !== 0} onClick={() => {
          rowData.activate === false ? toastr.error('Utilisateur inactif. Merci de l\'activer avant de procéder à l\'affectation des projets') :
            this.affecter(rowData.id)
        }}> Affecter</Button>
      ),
    },
  ]
  componentDidMount() {
    this.props.onFetchUsers(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search, '', true);
    this.props.onFetchProjects(this.props.token);

    this.intervalID = setInterval( this.props.onFetchUsers(this.props.token, this.state.page, this.state.itemsPerPage, this.state.search, '', true), 500);
  }

  componentWillUnmount() {
    /*
      stop getData() from continuing to run even
      after unmounting this component
    */
    clearInterval(this.intervalID);
  }

  handleLanguage = (langValue) => {
    this.setState({ language: langValue });
  }

  setUserToProdProject = (idU, idP) => {
    this.props.onFetchUserToProdProject(this.props.token, idU, idP);
  }

  affecter = (idU) => {
    const project = this.state.language;
    if (this.state.language === '') {
      toastr.error('Veuilliez choisir un projet!');
    } else {
      for (let i = 0; i < project.length; i++) {
        this.setUserToProdProject(idU, project[i]);
        this.componentDidMount();
      }
    }
  }

  submitHandler = (resetPwdBody) => {
    this.props.onResetPwd(this.props.token, resetPwdBody);
  }
  //   activate users
  waitForUpdateUser(id) {
    this.props.onActivateUser(this.props.token, id);
  }

  removeUserFromProd = (idP, idU) => {
    this.props.onDeleteUser(this.props.token, idP, idU);
    this.componentDidMount();
  }

  onSearchHandler = (searchText) => {
    if (this.state.page !== 0) {
      this.setState({ page: 0 })
    }

    this.setState({ search: searchText });
    this.props.onFetchUsers(this.props.token, 0, this.state.itemsPerPage, searchText, '', true)
  }

  onPageChangeHandler = (event, newPage) => {
    this.setState({ page: newPage })
    this.props.onFetchUsers(this.props.token, newPage, this.state.itemsPerPage, this.state.search, '', true);
  }

  onRowsPerPageHandler = (pageSize) => {
    if (this.state.page !== 0) {
      this.setState({ page: 0 })
    }
    this.setState({ itemsPerPage: pageSize })
    this.props.onFetchUsers(this.props.token, 0, pageSize, this.state.search, '', true);

  }
  render() {
    const usersTableData = [];

    for (const key in this.props.users) {
      usersTableData.push({
        ...this.props.users[key],

      });
    }
    const users = (<MaterialTable
      title="Liste des utilisateurs"
      columns={this.columns}
      data={usersTableData}
      LoadingComponent={Spinner}
      editable={{
        isDeletable: rowData => rowData.projects.length !== 0,
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const index = oldData.projects[0].id;
                usersTableData.splice(index, 1);
                this.removeUserFromProd(oldData.projects[0].id, oldData.id)
              }
              resolve()
            }, 1500)
          }),
          cellStyle: {},
        onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
            return new Promise((resolve, reject) => {
                console.log('newValue: ' + newValue);
                setTimeout(resolve, 4000);
            });
        }
      }}
      localization={{
        body: {
          editRow: {
          deleteText: 'Etes vous sûre de vouloir faire la désaffectation?' }
        }
      }}
      options={{
        pageSizeOptions: [5, 10, 20],
        pageSize: this.state.itemsPerPage,
        maxBodyHeight: '450px',
        selection: true,
        selectionProps: rowData => ({
          disabled: rowData.activate === true,
          color: 'primary'
        }),

        rowStyle: rowData => ({ backgroundColor: rowData.tableData.checked ? '#E0E9E7' : '' }),
        actionsColumnIndex: 6,
        headerStyle: {
          position: 'sticky',
          top: 0,
          fontSize: '19px',
          width: "auto",
          tableLayout: "auto"
        },
      }}

      icons={{
        Edit: BorderColorRoundedIcon,
        Search: YoutubeSearchedForIcon,
        ResetSearch: BackspaceIcon,
      }}
      isLoading={this.props.loading}
      onSearchChange={this.onSearchHandler}
      onChangeRowsPerPage={this.onRowsPerPageHandler}
      components={{
        Pagination: props => (
          <TablePagination
            {...props}
            count={this.props.totaC}
            page={this.state.page}
            onChangePage={this.onPageChangeHandler}
          />
        )
      }}
      actions={[
        {
          tooltip: 'Activer les utilisateurs séléctionnés',
          icon: () => {
            return (
              <Button style={{ backgroundColor: '#37035E' }}>Activer les utilisateurs </Button>
            )
          },
          onClick: (evt, rowData) => {
            for (let i = 0; i < rowData.length; i++) {
              this.state.emails.push(rowData[i].email)
              this.setState({
                emails: this.state.emails
              });
            }
            var emails = {
              "email": this.state.emails
            };
            this.setState({
              emails: []
            });
            this.submitHandler(JSON.stringify(emails));
            this.waitForUpdateUser(JSON.stringify(emails));
            this.componentDidMount();
          },
        },
      ]}
    />);

    const goBack = (
      <div >
        <br></br>
        <Button style={{ border: 'white' }} onClick={() => this.props.history.goBack()}>
          <FaAngleDoubleLeft size={18} />
          Retour
        </Button>
      </div>
    )

    return (
      <Container>
        <br></br>
        {goBack}
        <br></br>
        {users}
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    users: state.user.users,
    loading: state.user.loading,
    updatedUser: state.user.user,
    token: state.auth.token,
    totaC: state.user.totalcount,
    projects: state.project.projects,
    deleteUser: state.deleteUser.deleteUser,
    emails: state.email.emails,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onFetchUsers: (token, nbPage, itemsPerPage, searchTxt, roles, pagination) => dispatch(actions.fetchUsers(token, nbPage, itemsPerPage, searchTxt, roles, pagination)),
    onActivateUser: (token, id) => dispatch(actions.setUser(token, id)),
    onFetchProjects: (token) => dispatch(actions.fetchProjects(token)),
    onFetchUserToProdProject: (token, idU, idP) => dispatch(actions.fetchUserToProdProject(token, idU, idP)),
    onDeleteUser: (token, idP, idU) => dispatch(actions.deleteUsers(token, idP, idU)),
    onResetPwd: (token, resetPwdBody) => dispatch(actions.resetPwd(token, resetPwdBody))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
