import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for pres Prod Projects deleting
export const deletePresProdProjectSuccess = (presProdProjects, total) => {
  return {
    type: actionTypes.DELETE_PRES_PROD_PROJECTS_SUCCESS,
    presProdProjects: presProdProjects,
    total: total
  };
};

export const deletePresProdProjectFail = (error) => {
  return {
    type: actionTypes.DELETE_PRES_PROD_PROJECTS_FAIL,
    error: error
  };
};

export const deletePresProdProjectStart = () => {
  return {
    type: actionTypes.DELETE_PRES_PROD_PROJECTS_START
  };
};
// axios
export const deletePresProdProjects = (token, prodID, preprodId) => {
  return dispatch => {
    dispatch(deletePresProdProjectStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.post(`${BACK_BASE_URL}/api/deletePreprodProject/${prodID}/${preprodId}`, headerConfig)
      .then(res => {
        console.log(res)
        const deletedPresProdProjects = [];
        for (const key in res.data) {
          deletedPresProdProjects.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        dispatch(deletePresProdProjectSuccess(deletedPresProdProjects,total));
        toastr.error('Suppression', 'Les deux projets ne sont plus attachés ');
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(deletePresProdProjectFail(err));
      })
  }
}
