//////////// Auth Action-Types
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
//////////// redirection path
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';
//////////// synchronizing data Action-Types
export const FETCH_SYNCDATA_START = 'FETCH_SYNCDATA_START';
export const FETCH_SYNCDATA_SUCCESS = 'FETCH_SYNCDATA_SUCCESS';
export const FETCH_SYNCDATA_FAIL = 'FETCH_SYNCDATA_FAIL';
//////////// users Action-Types
export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAIL = 'FETCH_USERS_FAIL';
//////////// get all Users
export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAIL = 'GET_USER_FAIL';
//////////// get all Users
export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
//////////// Activate Users
export const SET_USER_START = 'SET_USER_START';
export const SET_USER_SUCCESS = 'SET_USER_SUCCESS';
export const SET_USER_FAIL = 'SET_USER_FAIL';
//////////// Comment Post
export const SET_NOTE_START = 'SET_NOTE_START';
export const SET_NOTE_SUCCESS = 'SET_NOTE_SUCCESS';
export const SET_NOTE_FAIL = 'SET_NOTE_FAIL';
//////////// delete comment
export const DELETE_NOTE_START = 'DELETE_NOTE_START';
export const DELETE_NOTE_SUCCESS = 'DELETE_NOTE_SUCCESS';
export const DELETE_NOTE_FAIL = 'DELETE_NOTE_FAIL';
//////////// projects Action-Types
export const FETCH_PROJECTS_START = 'FETCH_PROJECTS_START';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAIL = 'FETCH_PROJECTS_FAIL';
//////////// lists Action-Types
export const FETCH_LISTS_START = 'FETCH_LISTS_START';
export const FETCH_LISTS_SUCCESS = 'FETCH_LISTS_SUCCESS';
export const FETCH_LISTS_FAIL = 'FETCH_LISTS_FAIL';
//////////// plugin update Action-Types
export const FETCH_UPDATES_START = 'FETCH_UPDATES_START';
export const FETCH_UPDATES_SUCCESS = 'FETCH_UPDATES_SUCCESS';
export const FETCH_UPDATES_FAIL = 'FETCH_UPDATES_FAIL';
//////////// core update Action-Types
export const FETCH_CORE_UPDATES_START = 'FETCH_CORE_UPDATES_START';
export const FETCH_CORE_UPDATES_SUCCESS = 'FETCH_CORE_UPDATES_SUCCESS';
export const FETCH_CORE_UPDATES_FAIL = 'FETCH_CORE_UPDATES_FAIL';
//////////// pres prod projects Action-Types
export const FETCH_PRES_PROD_PROJECTS_START = 'FETCH_PRES_PROD_PROJECTS_START';
export const FETCH_PRES_PROD_PROJECTS_SUCCESS = 'FETCH_PRES_PROD_PROJECTS_SUCCESS';
export const FETCH_PRES_PROD_PROJECTS_FAIL = 'FETCH_PRES_PROD_PROJECTS_FAIL';
//////////// get preprod projects Action-Types
export const FETCH_PREPROD_PROJECTS_START = 'FETCH_PREPROD_PROJECTS_START';
export const FETCH_PREPROD_PROJECTS_SUCCESS = 'FETCH_PREPROD_PROJECTS_SUCCESS';
export const FETCH_PREPROD_PROJECTS_FAIL = 'FETCH_PREPROD_PROJECTS_FAIL';
//////////// get plugin detail Action-Types
export const FETCH_DETAILS_PLUGIN_START = 'FETCH_DETAILS_PLUGIN_START';
export const FETCH_DETAILS_PLUGIN_SUCCESS = 'FETCH_DETAILS_PLUGIN_SUCCESS';
export const FETCH_DETAILS_PLUGIN_FAIL = 'FETCH_DETAILS_PLUGIN_FAIL';
//////////// set preprod project to prod Action-Types
export const FETCH_DETAILS_PLUGIN_PREPROD_START = 'FETCH_DETAILS_PLUGIN_PREPROD_START';
export const FETCH_DETAILS_PLUGIN_PREPROD_SUCCESS = 'FETCH_DETAILS_PLUGIN_PREPROD_SUCCESS';
export const FETCH_DETAILS_PLUGIN_PREPROD_FAIL = 'FETCH_DETAILS_PLUGIN_PREPROD_FAIL';
//////////// set user to prod project Action-Types
export const FETCH_USER_PROD_PROJECTS_START = 'FETCH_USER_PROD_PROJECTS_START';
export const FETCH_USER_PROD_PROJECTS_SUCCESS = 'FETCH_USER_PROD_PROJECTS_SUCCESS';
export const FETCH_USER_PROD_PROJECTS_FAIL = 'FETCH_USER_PROD_PROJECTS_FAIL';
//////////// get project detail Action-Types
export const FETCH_DETAILS_PROJECT_START = 'FETCH_DETAILS_PROJECT_START';
export const FETCH_DETAILS_PROJECT_SUCCESS = 'FETCH_DETAILS_PROJECT_SUCCESS';
export const FETCH_DETAILS_PROJECT_FAIL = 'FETCH_DETAILS_PROJECT_FAIL';
//////////// if plugin is present Action-Types
export const FETCH_IF_PLUGIN_PRESENT_START = 'FETCH_IF_PLUGIN_PRESENT_START';
export const FETCH_IF_PLUGIN_PRESENT_SUCCESS = 'FETCH_IF_PLUGIN_PRESENT_SUCCESS';
export const FETCH_IF_PLUGIN_PRESENT_FAIL = 'FETCH_IF_PLUGIN_PRESENT_FAIL';
//////////// delete pres prod projects Action-Types
export const DELETE_PRES_PROD_PROJECTS_START = 'DELETE_PRES_PROD_PROJECTS_START';
export const DELETE_PRES_PROD_PROJECTS_SUCCESS = 'DELETE_PRES_PROD_PROJECTS_SUCCESS';
export const DELETE_PRES_PROD_PROJECTS_FAIL = 'DELETE_PRES_PROD_PROJECTS_FAIL';
//////////// delete users Action-Types
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL';
//////////// reset pwd Action-Types
export const RESETPWD_START = 'RESETPWD_START';
export const RESETPWD_SUCCESS = 'RESETPWD_SUCCESS';
export const RESETPWD_FAIL = 'RESETPWD_FAIL';
//////////// new pwd Action-Types
export const NEWPWD_START = 'NEWPWD_START';
export const NEWPWD_SUCCESS = 'NEWPWD_SUCCESS';
export const NEWPWD_FAIL = 'NEWPWD_FAIL';
//////////// fetch current user projects Action-Types
export const MYPROJECTS_START = 'MYPROJECTS_START';
export const MYPROJECTS_SUCCESS = 'MYPROJECTS_SUCCESS';
export const MYPROJECTS_FAIL = 'MYPROJECTS_FAIL';
//////////// forget pwd  Action-Types
export const FORGETPWD_START = 'FORGETPWD_START';
export const FORGETPWD_SUCCESS = 'FORGETPWD_SUCCESS';
export const FORGETPWD_FAIL = 'FORGETPWD_FAIL';
