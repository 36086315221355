import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import '../../styles/main.css'
import { FaSync, FaRegSun, FaCheckSquare } from "react-icons/fa";
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Card from 'react-bootstrap/Card'
import ButtonLoader from '../../containers/ProjectLists/ButtonLoader';

class Project extends Component {
  state = {
    loading: false,
  }

  updateCore = (id) => {
    this.props.onFetchCoreUpdates(this.props.token, id);
  }

  render() {
    return (
      <Card bg='white' text='dark' style={{ width:'auto', border: '3px solid' }} >
        <Card.Body>
          {
            this.props.project.newVersion !== "" ?
              <span >
                <FaRegSun style={{ fontSize: '25px', color: 'ffcc00' }} />
                <Card.Title> <h2>{this.props.project.name} </h2> </Card.Title>
              </span>
              :
              <span >
                <FaCheckSquare style={{ color: 'green', fontSize: '25px' }} />
                <Card.Title> <h2>{this.props.project.name} </h2> </Card.Title>
              </span>
          }
          <hr></hr>
          <b>Version Wordpress actuelle</b> : {this.props.project.version}
          <br></br>
          {
            this.props.project.newVersion !== "" ?
              <p><b>Version Wordpress disponible:</b> {this.props.project.newVersion}</p>
              :
              <p><b>Version Wordpress disponible:</b> Votre version est à jour</p>
          }
          {
            this.props.project.newVersion !== "" ?
              <center>        Vous pouvez mettre à jour la version wordpress vers {this.props.project.newVersion}
              <p>  <ButtonLoader newVersion={this.props.project.newVersion} loading={this.state.loading} id={this.props.project.id} />
              </p></center>
              :
              <FaSync hidden />
          }
          <br></br>
          <center>
            <NavLink style={{ border: '1px solid green' }} to={`/list/${this.props.project.id}`} onClick={() => this.props.viewMovieInfo(this.props.project.id)}
              color="primary">
              Voir details
            </NavLink>
          </center>
        </Card.Body>
        <Card.Footer>
        <small className="text-muted">Ajouté le {this.props.project.time}</small>
    </Card.Footer>
      </Card>
    )
  }
}
const mapStateToProps = state => {
  return {
    coreUpdates: state.coreUpdate.coreUpdates,
    loading: state.coreUpdate.loading,
    token: state.auth.token,
    totaC: state.coreUpdate.totalcount,
    error: state.coreUpdate.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchCoreUpdates: (token, id) => dispatch(actions.fetchCoreUpdates(token, id))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Project)
