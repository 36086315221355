import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  presProdProjects: [],
  loading: false
}
const fetchPresProdProjectStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
}

const fetchPresProdProjectSuccess = (state, action) => {
  return updateObject(state, {
    presProdProjects: action.presProdProjects,
    loading: false
  });
};

const fecthPresProdProjectFail = (state, action) => {
  return updateObject(state, {
    loading: false
  });
}

const reducerPresProdProjects = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_PRES_PROD_PROJECTS_START: return fetchPresProdProjectStart(state, action);
    case actionTypes.FETCH_PRES_PROD_PROJECTS_SUCCESS: return fetchPresProdProjectSuccess(state, action);
    case actionTypes.FETCH_PRES_PROD_PROJECTS_FAIL: return fecthPresProdProjectFail(state, action);
    default: return state;
  }
};

export default reducerPresProdProjects;
