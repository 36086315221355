import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for Detail Plugin fetching
export const fetchDetailPluginSuccess = (detailPlugin,total) => {
  return {
    type: actionTypes.FETCH_DETAILS_PLUGIN_SUCCESS,
    detailPlugin: detailPlugin,
    total: total
  };
};

export const fetchDetailPluginFail = (error) => {
  return {
    type: actionTypes.FETCH_DETAILS_PLUGIN_FAIL,
    error: error
  };
};

export const fetchDetailPluginStart = () => {
  return {
    type: actionTypes.FETCH_DETAILS_PLUGIN_START
  };
};
// axios
export const fetchDetailPlugin = (token, pluginId) => {
  return dispatch => {
    dispatch(fetchDetailPluginStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(`${BACK_BASE_URL}/api/detailsList/${pluginId}`, headerConfig)
      .then(res => {
        const fecthedDetailPlugin = [];
        for (const key in res.data) {
            fecthedDetailPlugin.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        localStorage.setItem('detailsList', pluginId);
        dispatch(fetchDetailPluginSuccess(fecthedDetailPlugin, total));
      })
      .catch(err => {
        // in case the token is expired
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchDetailPluginFail(err));
      })
  }
}
