import * as actionTypes from './actions-types';
import axios from 'axios';
import { logout } from './auth';
import { toastr } from 'react-redux-toastr';
const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for Detail Plugin fetching
export const fetchDetailPluginPreprodSuccess = (detailPluginPreprod,total) => {
  return {
    type: actionTypes.FETCH_DETAILS_PLUGIN_PREPROD_SUCCESS,
    detailPluginPreprod: detailPluginPreprod,
    total: total
  };
};

export const fetchDetailPluginPreprodFail = (error) => {
  return {
    type: actionTypes.FETCH_DETAILS_PLUGIN_PREPROD_FAIL,
    error: error
  };
};

export const fetchDetailPluginPreprodStart = () => {
  return {
    type: actionTypes.FETCH_DETAILS_PLUGIN_PREPROD_START
  };
};
// axios
export const fetchDetailPluginPreprod = (token, pluginId) => {
  return dispatch => {
    dispatch(fetchDetailPluginPreprodStart());
    const headerConfig = {
      headers: { Authorization: `Bearer ${token}` }
    };
    axios.get(`${BACK_BASE_URL}/api/detailsListPreprod/${pluginId}`, headerConfig)
      .then(res => {
        const fecthedDetailPluginPreprod = [];
        for (const key in res.data) {
            fecthedDetailPluginPreprod.push({
            ...res.data[key],
            counter: res.data[key].id,
          });
        }
        const total = res.data;
        dispatch(fetchDetailPluginPreprodSuccess(fecthedDetailPluginPreprod, total));
      })
      .catch(err => {
        if (err.response.status === 401) {
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(fetchDetailPluginPreprodFail(err));
      })
  }
}
