import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { connect } from 'react-redux';
import Auth from './containers/Auth/Auth';
import Logout from './containers/Auth/Logout/Logout';
import AdminPanel from './containers/AdminPanel/AdminPanel';
import * as actions from './store/actions/index';
import Layout from './hoc/Layout/Layout';
import Projects from './containers/Projects/Projects';
import Users from './containers/Users/Users';
import ProjectLists from './containers/ProjectLists/ProjectLists';
import Detail from './containers/PluginDetails/Details';
import Details from './containers/ProjetDetails/Details';
import Profile from './containers/Profile/Profile';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import MyProjects from './containers/DeveloperPanel/MyProjects';
import ForgetPwd from './containers/ForgetPwd/ForgetPwd';

class App extends Component {
  // check token validity
  componentDidMount() {
    this.props.onTryAutoSignup();
  }

  render() {
    let routes = (
      <Switch>
        <Route exact path="/" component={Auth} />
        <Route path="/reset" component={ResetPassword} />
        <Route path="/forgetpwd" component={ForgetPwd} />
      </Switch>
    );
    if (this.props.isAuthenticated) {
      routes = (
        <Layout>
          <Switch>
            <Route path="/adminpanel" component={AdminPanel} />
            <Route path="/projects" component={Projects} />
            <Route path="/myprojects" component={MyProjects} />
            <Route path="/users" component={Users} />
            <Route path="/list/:id" component={ProjectLists} />
            <Route path="/pluginDetail/:id" component={Detail} />
            <Route path="/projetDetail/:id" component={Details} />
            <Route path="/profile" component={Profile} />
            <Route path="/logout" component={Logout} />
            <Redirect to ="/projects"/>
          </Switch>
        </Layout>
      );
    }
    return (
      <div className="App">
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          getState={(state) => state.toastr}
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar={false}
          closeOnToastrClick />
        {routes}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.auth.token !== null
  };
};
// to add dispatch state to props
const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
