import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import '../../styles/sidebar.css';
import '../../styles/header.css';
import '../../styles/main.css';
import { Row, Col, Button } from 'reactstrap';
import Tab from './Tab';
import { FaAngleDoubleLeft } from "react-icons/fa";

class Details extends Component {
  intervalID;

  componentDidMount() {
    this.props.onFetchDetailProject(this.props.token, this.props.match.params.id);
    this.props.onGetUsers(this.props.token);
    this.intervalID = setInterval( this.props.onFetchDetailProject(this.props.token, this.props.match.params.id), 500);
  }

  componentWillUnmount() {
    /*
      stop getData() from continuing to run even
      after unmounting this component
    */
    clearInterval(this.intervalID);
  }

  render() {

    console.log(this.props)
    return (
      <div style={{marginRight:'15cm'}}>
        <br></br>
        <Row display="inline">
          <Col>
            <div>
              <br></br>
              <Button style={{ border: 'white' }} onClick={() => this.props.history.goBack()}>
                <FaAngleDoubleLeft size={18} />
                Retour
              </Button>
            </div>
            <Tab componentDidMount={ () => this.componentDidMount() }  loading={this.props.loading} id={this.props.match.params.id}
            detailProject={this.props.detailProject} users={this.props.users} />
          </Col>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    loading: state.detailProject.loading,
    token: state.auth.token,
    detailProject: state.detailProject.detailProject,
    users: state.user.users,
    allUsers: state.user.allUsers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchDetailProject: (token, id) => dispatch(actions.fetchDetailProject(token, id)),
    onGetUsers: (token) => dispatch(actions.getUser(token)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
