import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
    deleteUser: [],
    loading: false
}
const deleteUserStart = (state, action) => {
  return updateObject(state, {
    loading: true
  });
}

const deleteUserSuccess = (state, action) => {
  return updateObject(state, {
    deleteUser: action.deleteUser,
    loading: false
  });
};

const deleteUserFail = (state, action) => {
  return updateObject(state, {
    loading: false
  });
}

const reducerDeleteUser = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_USER_START: return deleteUserStart(state, action);
    case actionTypes.DELETE_USER_SUCCESS: return deleteUserSuccess(state, action);
    case actionTypes.DELETE_USER_FAIL: return deleteUserFail(state, action);
    default: return state;
  }
};

export default reducerDeleteUser;
