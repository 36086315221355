import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  lists: [],
  loading: false
}
const fetchListStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const fetchListSuccess = (state, action) => {
  return updateObject(state, {
    lists: action.lists,
    loading: false
  });
};

const fecthListFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerList = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LISTS_START: return fetchListStart(state, action);
    case actionTypes.FETCH_LISTS_SUCCESS: return fetchListSuccess(state, action);
    case actionTypes.FETCH_LISTS_FAIL: return fecthListFail(state, action);
    default: return state;
  }
};

export default reducerList;
