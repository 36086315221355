import * as actionTypes from '../actions/actions-types';
import { updateObject } from '../../shared/utility';

const initialState = {
  emails: [],
  email: null,
  loading: false,
  totalcount: 0,
}
const resetPwdStart = (state, action) => {
  return updateObject(state, { loading: true });
}

const resetPwdSuccess = (state, action) => {
  return updateObject(state, {
    emails: action.emails,
    email: action.email,
    loading: false,
    totalcount: action.total
  });
}

const resetPwdFail = (state, action) => {
  return updateObject(state, { loading: false })
}

const reducerResetPwd = (state = initialState, action) => {
  switch (action.type) {
    // add actions
    case actionTypes.RESETPWD_START: return resetPwdStart(state, action);
    case actionTypes.RESETPWD_SUCCESS: return resetPwdSuccess(state, action);
    case actionTypes.RESETPWD_FAIL: return resetPwdFail(state, action);
    default: return state;
  }
}

export default reducerResetPwd;
