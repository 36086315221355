import * as actionTypes from './actions-types';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { logout } from './auth';

const BACK_BASE_URL = process.env.REACT_APP_BACK_BASE_URL;
///  for projects fetching
export const myProjectsSuccess = (myProjects, myProject) => {
  return {
    type: actionTypes.MYPROJECTS_SUCCESS,
    myProjects: [...myProjects],
    myProject: myProject
  };
};

export const myProjectsFail = (error) => {
  return {
    type: actionTypes.MYPROJECTS_FAIL,
    error: error
  };
};

export const myProjectsStart = () => {
  return {
    type: actionTypes.MYPROJECTS_START
  };
};

export const myProjects = (token, myProjectsData) => {
  return dispatch => {
    dispatch(myProjectsStart());
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    };
    axios.post(`${BACK_BASE_URL}/getCurrentUser`, myProjectsData, { headers })
      .then(res => {
        dispatch(myProjectsSuccess(res.data, myProjectsData));
      })
      .catch(err => {
        if (err.response.status === 401) {
          window.location="/";
          toastr.warning(err.response.data.message, 'Sign in again');
          dispatch(logout());
        }
        dispatch(myProjectsFail(err));
      })
  }
}
