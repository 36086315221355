import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

export default class SelectUser extends React.Component {
  state = {
    projectName: [],
    projectId: [],
  }
  handleChange = (event, obj) => {
    if (obj != null) {
      this.setState({ projectName: obj.name });
      this.state.projectId.push(obj.id)
      this.setState({
        projectId: this.state.projectId
      });
      var id = this.state.projectId.slice(Math.max(this.state.projectId.length - 1, 0));
      this.props.onSelectLanguage(id);
    }
  };
  render() {
    return (
      <Autocomplete
        options={this.props.projects}
        getOptionDisabled={(option) => option.user !== ''}
        getOptionLabel={(option) => option.name}
        style={{ width: 150 }}
        renderInput={(params) => <TextField {...params} label="Projet" variant="outlined" />}
        onChange={(e, obj) => this.handleChange(e, obj)}
      />
    );
  }
}
